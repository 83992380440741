// This component is used to get name, username and email from a list of users
import React from "react";
import { useTranslation } from "react-i18next";
import dateFormat from "dateformat";
import { Tooltip } from "react-tooltip";

const Resumen = ({
    selectedTickets,
    event,
    currentSessionId,
    discount,
    getTotal,
    errorQuantityDiscount,
    getSelectedTicketsCount,
}) => {
    const { t } = useTranslation();

    let tickettypes = event.open_sessions.filter((session) => {
        return session.id == currentSessionId;
    })[0].tickettypes;

    let currentSession = event.open_sessions.filter((session) => {
        return session.id == currentSessionId;
    })[0];

    function toggleResumenOpen() {
        document.getElementById("resumen").classList.toggle("hidden2");
    }

    return (
        <div id="resumen-container">
            <div id="resumen" className="widget open hidden2">
                <div className="hider" onClick={toggleResumenOpen}>
                    <svg
                        width="19"
                        height="9"
                        viewBox="0 0 19 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0.196552 1.01449C0.0655174 0.898551 0 0.753624 0 0.594203C0 0.434783 0.0655174 0.289855 0.196552 0.173913C0.45862 -0.0579707 0.884483 -0.0579707 1.14655 0.173913L9.5 7.56522L17.8534 0.173913C18.1155 -0.057971 18.5414 -0.057971 18.8034 0.173913C19.0655 0.405797 19.0655 0.782609 18.8034 1.01449L9.975 8.82609C9.71293 9.05797 9.28707 9.05797 9.025 8.82609L0.196552 1.01449Z"
                            fill="black"
                        />
                    </svg>
                </div>

                <div className="event-info-mini">
                    <div className="event-name">{event.title}</div>
                    <div className="event-date">
                        <svg
                            width="13"
                            height="15"
                            viewBox="0 0 13 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M5.74167 12.2625L3.17778 9.6L4.225 8.5125L5.74167 10.0875L8.775 6.9375L9.82222 8.025L5.74167 12.2625ZM0 15V1.5H2.16667V0H3.61111V1.5H9.38889V0H10.8333V1.5H13V15H0ZM1.44444 13.5H11.5556V6H1.44444V13.5Z"
                                fill="black"
                            />
                        </svg>

                        {dateFormat(currentSession.start_day, "dd-mm-yyyy")}

                        {currentSession.end_day &&
                            " - " + dateFormat(currentSession.end_day, "dd-mm-yyyy")}

                    </div>
                    <div className="event-time">
                        <svg
                            width="17"
                            height="17"
                            viewBox="0 0 17 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M11.3394 8.5H8.47389V4.33333M1.10547 8.5C1.10547 9.48491 1.29606 10.4602 1.66636 11.3701C2.03665 12.2801 2.57941 13.1069 3.26363 13.8033C3.94785 14.4997 4.76014 15.0522 5.65412 15.4291C6.54809 15.806 7.50625 16 8.47389 16C9.44152 16 10.3997 15.806 11.2937 15.4291C12.1876 15.0522 12.9999 14.4997 13.6841 13.8033C14.3684 13.1069 14.9111 12.2801 15.2814 11.3701C15.6517 10.4602 15.8423 9.48491 15.8423 8.5C15.8423 7.51509 15.6517 6.53982 15.2814 5.62987C14.9111 4.71993 14.3684 3.89314 13.6841 3.1967C12.9999 2.50026 12.1876 1.94781 11.2937 1.5709C10.3997 1.19399 9.44152 1 8.47389 1C7.50625 1 6.54809 1.19399 5.65412 1.5709C4.76014 1.94781 3.94785 2.50026 3.26363 3.1967C2.57941 3.89314 2.03665 4.71993 1.66636 5.62987C1.29606 6.53982 1.10547 7.51509 1.10547 8.5Z"
                                stroke="black"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>

                        {currentSession.start_hour.slice(0, 5)}
                    </div>
                    <div className="event-location">
                        <svg
                            width="17"
                            height="18"
                            viewBox="0 0 17 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M9.5 9.125C8.97904 9.125 8.47942 8.92746 8.11104 8.57583C7.74267 8.22419 7.53571 7.74728 7.53571 7.25C7.53571 6.75272 7.74267 6.27581 8.11104 5.92417C8.47942 5.57254 8.97904 5.375 9.5 5.375C10.021 5.375 10.5206 5.57254 10.889 5.92417C11.2573 6.27581 11.4643 6.75272 11.4643 7.25C11.4643 7.49623 11.4135 7.74005 11.3148 7.96753C11.216 8.19502 11.0714 8.40172 10.889 8.57583C10.7066 8.74994 10.49 8.88805 10.2517 8.98227C10.0134 9.0765 9.75795 9.125 9.5 9.125ZM9.5 2C8.04131 2 6.64236 2.55312 5.61091 3.53769C4.57946 4.52226 4 5.85761 4 7.25C4 11.1875 9.5 17 9.5 17C9.5 17 15 11.1875 15 7.25C15 5.85761 14.4205 4.52226 13.3891 3.53769C12.3576 2.55312 10.9587 2 9.5 2Z"
                                fill="black"
                            />
                        </svg>
                        {event.place.street}, {event.place.city}
                    </div>
                </div>
                <div className="widget-title">
                    <div className="number">
                        {getSelectedTicketsCount(selectedTickets)}
                    </div>
                    <div className="title">{t("event.my_cart")}</div>
                </div>
                {Object.keys(selectedTickets).map((tickettype) => {
                    let qty = selectedTickets[tickettype];
                    let tickettypeInfo = tickettypes.filter((tickettypeInfo) => {
                        return tickettypeInfo.id == tickettype;
                    })[0];
                    if (!qty) return null;
                    return (
                        <div className="tickettype-mini" key={tickettype}>
                            <div className="tickettype-name">{tickettypeInfo.name}</div>
                            <div className="tickettype-price-qty">
                                <div className="tickettype-qty">
                                    {qty} x {tickettypeInfo.price}€
                                </div>
                                <div className="tickettype-price">
                                    {qty * tickettypeInfo.price}€
                                </div>
                            </div>
                        </div>
                    );
                })}

                <div className="tickettype-mini" key={"admin_fee"}>
                    <div className="tickettype-name"></div>
                    <div className="tickettype-price-qty">
                        <div className="tickettype-qty" style={{ color: "#373535" }}>
                            {t('event.admin_fee')} <small className="small-i" data-tooltip-id="mys-tooltip" data-tooltip-content="Gracias a esta aportación, podemos ofrecerte los mejores y más seguros servicios de tickets.">i</small>
                            {/* {Math.round(event.user_fee * 100 * 100) / 100}% */}
                        </div>
                        <Tooltip id="mys-tooltip" />
                        <div className="tickettype-price">
                            {getTotal(tickettypes, selectedTickets, discount, event.user_fee)}€
                        </div>
                    </div>
                </div>

                <div className="total-mini">
                    <div className="total-text">{t("event.total")}</div>
                    <div className="total-price">
                        {getTotal(tickettypes, selectedTickets, discount, (1 + parseFloat(event.user_fee)))}€
                    </div>
                </div>
            </div>

            <div id="resumen-bar" onClick={toggleResumenOpen}>
                <svg
                    width="16"
                    height="9"
                    viewBox="0 0 16 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M15.3448 7.98551C15.4483 8.10145 15.5 8.24638 15.5 8.4058C15.5 8.56522 15.4483 8.71014 15.3448 8.82609C15.1379 9.05797 14.8017 9.05797 14.5948 8.82609L8 1.43478L1.40517 8.82609C1.19828 9.05797 0.862069 9.05797 0.655172 8.82609C0.448276 8.5942 0.448276 8.21739 0.655172 7.98551L7.625 0.173913C7.8319 -0.0579706 8.1681 -0.0579706 8.375 0.173913L15.3448 7.98551Z"
                        fill="black"
                    />
                </svg>

                <div className="number">{getSelectedTicketsCount(selectedTickets)}</div>
                <div className="total-text">{t("event.total")}</div>
                <div className="total-price">
                    {getTotal(tickettypes, selectedTickets, discount, (1 + parseFloat(event.user_fee)))}€
                </div>
            </div>
        </div>
    );
};

export default Resumen;
