import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import i18nBackend from "i18next-http-backend";
import translationEn from "./lang/en.json";
import translationEs from "./lang/es.json";


const getCurrentLang = () => {
    const lang = localStorage.getItem("lang");
    if (lang) {
        return lang;
    }
    return "es";
};

const resources = {
    en: {
        translation: translationEn
    },
    es: {
        translation: translationEs
    }

};

i18n
    // .use(i18nBackend)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: "en",
        // debug: true,
        lng: getCurrentLang(),
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
