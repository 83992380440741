import React from "react";
import "../styles/test.scss";
import "../styles/app.scss";

const CompanyHeader = ({ id, background, children }) => {
  return (
    <div
      id={id}
      className="company-container"
      style={{
        "--background": `url(${background})`,
        // "--main_color": company.main_color,
        // "--text_color": company.text_color,
        // "--background_color": company.background_color,
      }}
    >
      <div className="company-header-overlay"> </div>
      <div className="company-header"></div>

      <div className="content">{children}</div>
    </div>
  );
};

export default CompanyHeader;
