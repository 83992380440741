import { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";

import BillingDetailsFields from "./checkout/BillingDetailsFields";
import CheckoutError from "./checkout/CheckoutError";

import { useTranslation } from "react-i18next";
import { useAuthStore } from "../../stores/AuthStore";

const CheckoutForm = ({ price, onSuccessfulCheckout, order, onCancel }) => {
    const [isProcessing, setProcessingTo] = useState(false);
    const [checkoutError, setCheckoutError] = useState();

    const stripe = useStripe();
    const elements = useElements();

    const { t } = useTranslation();
    const { isLoggedIn, userData, logout } = useAuthStore();

    // TIP
    // use the cardElements onChange prop to add a handler
    // for setting any errors:

    const handleCardDetailsChange = (ev) => {
        ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
    };

    const handleFormSubmit = async (ev) => {
        ev.preventDefault();

        const billingDetails = {
            name: userData.name,
            email: userData.email,
            /* address: {
              city: ev.target.city.value,
              line1: ev.target.address.value,
              state: ev.target.state.value,
              postal_code: ev.target.zip.value,
            }, */
        };

        setProcessingTo(true);

        const cardElement = elements.getElement("card");

        try {
            //         "Content-Type": 'application/json'

            const { data: clientSecret } = await axios.post(
                process.env.REACT_APP_API_DOMAIN + "/orders/stripeIntent",
                {
                    amount: price * 100,
                    order_id: order.id,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            );

            const paymentMethodReq = await stripe.createPaymentMethod({
                type: "card",
                card: cardElement,
                billing_details: billingDetails,
            });

            if (paymentMethodReq.error) {
                setCheckoutError(paymentMethodReq.error.message);
                setProcessingTo(false);
                return;
            }

            const { error } = await stripe.confirmCardPayment(clientSecret, {
                payment_method: paymentMethodReq.paymentMethod.id,
            });

            if (error) {
                setCheckoutError(error.message);
                setProcessingTo(false);
                return;
            }

            onSuccessfulCheckout();
        } catch (err) {
            setCheckoutError(err.message);
        }
    };

    // Learning
    // A common ask/bug that users run into is:
    // How do you change the color of the card element input text?
    // How do you change the font-size of the card element input text?
    // How do you change the placeholder color?
    // The answer to all of the above is to use the `style` option.
    // It's common to hear users confused why the card element appears impervious
    // to all their styles. No matter what classes they add to the parent element
    // nothing within the card element seems to change. The reason for this is that
    // the card element is housed within an iframe and:
    // > styles do not cascade from a parent window down into its iframes

    const iframeStyles = {
        base: {
            color: "#222",
            fontSize: "14px",
            fontWeight: 300,
            border: "1px solid #D9D9D9",
            iconColor: "#fff",
            backgroundColor: "white",
            "::placeholder": {
                color: "#777",
            },
            width: "100%",
        },
        invalid: {
            iconColor: "#FF3333",
            color: "#FF3333",
        },
        complete: {
            iconColor: "#339933",
        },
    };

    const cardElementOpts = {
        iconStyle: "solid",
        style: iframeStyles,
        hidePostalCode: true,
    };

    return (
        <form onSubmit={handleFormSubmit} className="nice-form pay-form ">
            <div>
                <BillingDetailsFields />
            </div>
            <div>
                <label htmlFor="card-element">{t("event.credit_card")}</label>

                <div className="card-container">
                    <CardElement
                        options={cardElementOpts}
                        onChange={handleCardDetailsChange}
                    />
                </div>
            </div>
            {checkoutError && <CheckoutError>{checkoutError}</CheckoutError>}
            <div className="total standalone-total widget-footer">
                <a href="#" onClick={onCancel} className="button-link-cancel">
                    {t("event.cancel")}
                </a>
                {/* TIP always disable your submit button while processing payments */}
                <button className="simple-button" disabled={!stripe}>
                    {isProcessing
                        ? t("event.processing") + "..."
                        : t("event.pay") + ` ${price}€`}
                </button>
            </div>
        </form>
    );
};

export default CheckoutForm;
