import LoginForm from "../components/LoginForm";
import RegisterForm from "../components/PasswordForm";
import { useNavigate } from "react-router-dom";

import { useAuthStore } from "../stores/AuthStore";
import { useTranslation } from "react-i18next";
import PasswordForm from "../components/PasswordForm";

// axios.defaults.xsrfCookieName = "XSRF-TOKEN";
// axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

const SetPassword = () => {
  const { isLoggedIn } = useAuthStore();
  let navigate = useNavigate();

    const { t } = useTranslation();
    
    if (isLoggedIn) {
      navigate("/user/profile");
    }

  const handlePassword = (data) => {
    navigate("/user/profile");
  };


  return (
    <div className="container">
      <div className="white-block">
        {/* <Link to="/">back</Link> */}

        {!isLoggedIn && (
          <>
            <div className="form-container">
              <h1>{t("reset_password")}</h1>
              <PasswordForm onSuccess={handlePassword} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SetPassword;
