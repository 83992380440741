// This component is used to get name, username and email from a list of users
import React from "react";
import { useTranslation } from "react-i18next";
import { Calendar as Cal } from "react-calendar";
import dateFormat from "dateformat";

import "react-calendar/dist/Calendar.css";
import "../../styles/calendar.scss";

const Calendar = ({
  setCurrentSessionId,
  currentSessionId,
  sessions,
  currentStep,
}) => {
  let dates = sessions.map((session) => {
    return session.start_day.split("T")[0];
  });
  const { t } = useTranslation();

  let [currentDay, setCurrentDay] = React.useState("");

  return (
    <div id="calendar">
      <Cal
        onChange={(date) => {
          setCurrentDay(dateFormat(date, "yyyy-mm-dd"));
          //   setCurrentSessionId(event.target.value);
        }}
        name="session_id"
        tileDisabled={({ date }) =>
          dates.includes(dateFormat(date, "yyyy-mm-dd")) == false
        }

        //   disabled
      />
      {currentDay && (
              <div className="sessions">
                  {currentDay && (
                      <div className="mini-title">
                          {t('event.choose_time')}
                      </div>
                    )}
                          
          {sessions.map((session, idx) => {
            if (session.start_day.split("T")[0] == currentDay) {
              return (
                <div
                  className={`session ${
                    currentSessionId == session.id ? "active" : ""
                  }`}
                  key={idx}
                  onClick={() => setCurrentSessionId(session.id)}
                >
                  <div className="session-date">
                    {session.start_hour.slice(0, 5)}
                  </div>
                </div>
              );
            }
          })}
        </div>
      )}
    </div>
  );
};

export default Calendar;
