// This component is used to get name, username and email from a list of users
import React from "react";
import { useTranslation } from "react-i18next";

const Timer = ({ seconds }) => {
  const { t } = useTranslation();

  let minutes = Math.max(Math.floor(seconds / 60) , 0).toString();
  let secondsLeft = Math.max((seconds % 60), 0).toString();

    if (Math.floor(seconds / 60) == 0 && seconds % 60 == 0) {
        alert(t("event.time_is_up"));
        window.location.reload();
    }
    
  return (
    <div id="timer">
      <span className="left">
        {minutes.padStart(2, "0")}:{secondsLeft.padStart(2, "0")}
      </span>
      <div className="message right">{t("event.we_keep_your_tickets")}</div>
    </div>
  );
};

export default Timer;
