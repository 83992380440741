// This component is used to get name, username and email from a list of users
import React from "react";
import { useAuthStore } from "../../stores/AuthStore";
import { useTranslation } from "react-i18next";

const Form = ({ form, onChangeEvent, remoteid = "" }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="user-form">
        {/* <div className="title">{"form"}</div> */}
        <div className="user-info__name">
          <p
            className="description separator-div"
            dangerouslySetInnerHTML={{ __html: form.description }}
          ></p>
          <label htmlFor="name">
            {form.title}

            {form.is_mandatory == 1 && (<span className="text-danger">*</span>)}
          </label>
          {form.type_id == 1 && (
            <input
              type="text"
              name={`form_${form.id}`}
              id={"form-" + form.id + "-" + remoteid}
              onChange={onChangeEvent}
              defaultValue={""}
            />
          )}
          {form.type_id == 2 && (
            <textarea
              type="text"
              name={`form_${form.id}`}
              id={"form-" + form.id + "-" + remoteid}
              onChange={onChangeEvent}
              defaultValue={""}
            />
          )}
          {form.type_id == 3 && (
            <select
              id={"form-" + form.id + "-" + remoteid}
              name={`form_${form.id}`}
              onChange={onChangeEvent}
              multiple={form.is_multiple}
            >
              <option key="0" value="">
                {t("forms.select_an_option")}
              </option>
              {form.options.split("\r\n").map((option, idx) => {
                return (
                  <option key={idx} value={option}>
                    {option}
                  </option>
                );
              })}
            </select>
          )}
        </div>
      </div>
    </>
  );
};

export default Form;
