import "./../../styles/progress.scss";

const Progress = ({ step }) => {
  const percent = Math.round(((parseInt(step) + 1) / 6) * 220);
  return (
      <div className="wrapper-center">
      <div className="progress-bar">
        <svg
          className="progress"
          data-progress="10"
          x="0px"
          y="0px"
          viewBox="0 0 80 80"
        >
          <path
            className="track"
            d="M5,40a35,35 0 1,0 70,0a35,35 0 1,0 -70,0"
          />
          <path
            className="fill"
            d="M5,40a35,35 0 1,0 70,0a35,35 0 1,0 -70,0"
            style={{ strokeDashoffset: percent }}
          />
          <text className="value" x="50%" y="55%">
            {step + "/" + 5}
          </text>
          {/* <text className="text" x="50%" y="115%">
            HTML
          </text> */}
        </svg>
      </div>
    </div>
  );
};

export default Progress;
