// create form with react-hook-form and inputs name, surname, email, password, password_confirmation,
// phone, newsletter checkbox, birthday, cif, street, zip, province and country

import React, { useState } from "react";
import { useAuthStore } from "../stores/AuthStore";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import UserHeader from "../components/UserHeader";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

const UserProfile = ({ onSuccess }) => {
    const { isLoggedIn, userData, updateUserRequest } = useAuthStore();

    let navigate = useNavigate();

    const { t } = useTranslation();

    const [bErrors3, setBErrors3] = useState(false);
    const [bErrors4, setBErrors4] = useState(false);

    const {
        register: register1,
        handleSubmit: handleSubmit1,
        trigger: trigger1,
        watch: watch1,
        formState: { errors: errors1, isSubmitting: isSubmitting1 },
    } = useForm();

    const {
        register: register2,
        handleSubmit: handleSubmit2,
        trigger: trigger2,
        watch: watch2,
        formState: { errors: errors2, isSubmitting: isSubmitting2 },
    } = useForm();

    const {
        register: register3,
        handleSubmit: handleSubmit3,
        trigger: trigger3,
        watch: watch3,
        formState: { errors: errors3, isSubmitting: isSubmitting3 },
    } = useForm();

    const {
        register: register4,
        handleSubmit: handleSubmit4,
        trigger: trigger4,
        watch: watch4,
        formState: { errors: errors4, isSubmitting: isSubmitting4 },
    } = useForm();

    const onSubmit1 = (data) => {
        return updateUserRequest(data, onSuccess);
    };
    const onSubmit2 = (data) => {
        return updateUserRequest(data, onSuccess);
    };
    const onSubmit3 = (data) => {
        return updateUserRequest(data, (err) => {
            setBErrors3(err);
        });
    };
    const onSubmit4 = (data) => {
        return updateUserRequest(data, (err) => {
            setBErrors4(err);
        });
    };

    useEffect(() => {
        if (typeof userData.name !== "undefined") {
            console.log("trigger validation", userData);
            trigger1();
            trigger2();
            trigger3();
            trigger4();
        }
    }, [trigger1, trigger2, trigger3, trigger4, userData]);

    if (!isLoggedIn) {
        return <></>
    }


    return (
        <div className="user-info-container">
            <UserHeader active="profile">
                {isLoggedIn && (
                    <div>
                        <div className="profile-header">
                            <div className="profile-overlay">
                                <div className="breadcrumb">
                                    <a href="/">{t("home.home")}</a> / &nbsp;{t("profile.my_profile")}
                                </div>
                                <h1>{t("profile.my_profile")}</h1>
                                <p>{t("profile.my_profile_description")}</p>
                            </div>
                        </div>
                        <div className="profile-content">
                            <div className="profile-block left" style={{ order: 3 }}>
                                <div className="profile-icon">
                                    <svg
                                        width="25"
                                        height="24"
                                        viewBox="0 0 25 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M12.5 0C14.1576 0 15.7473 0.632141 16.9194 1.75736C18.0915 2.88258 18.75 4.4087 18.75 6C18.75 7.5913 18.0915 9.11742 16.9194 10.2426C15.7473 11.3679 14.1576 12 12.5 12C10.8424 12 9.25268 11.3679 8.08058 10.2426C6.90848 9.11742 6.25 7.5913 6.25 6C6.25 4.4087 6.90848 2.88258 8.08058 1.75736C9.25268 0.632141 10.8424 0 12.5 0ZM12.5 15C19.4062 15 25 17.685 25 21V24H0V21C0 17.685 5.59375 15 12.5 15Z"
                                            fill="white"
                                        />
                                    </svg>
                                </div>

                                <form
                                    method="post"
                                    className="nice-form"
                                    onSubmit={handleSubmit1((data) => onSubmit1(data))}
                                >
                                    <div>
                                        <label htmlFor="name">{t("forms.name")}</label>
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            defaultValue={userData.name}
                                            {...register1("name", { required: true })}
                                        />
                                        {errors1.name && errors1.name.type === "required" && (
                                            <span className="required">{t("forms.required")}</span>
                                        )}
                                    </div>
                                    <div>
                                        <label htmlFor="surname">{t("forms.surname")}</label>
                                        <input
                                            type="text"
                                            name="surname"
                                            id="surname"
                                            defaultValue={userData.surname}
                                            {...register1("surname", { required: true })}
                                        />
                                        {errors1.surname && errors1.surname.type === "required" && (
                                            <span className="required">{t("forms.required")}</span>
                                        )}
                                    </div>

                                    <div>
                                        <label htmlFor="gender_id">{t("forms.gender")}</label>
                                        <select
                                            name="gender_id"
                                            id="gender_id"
                                            defaultValue={userData.gender_id}
                                            {...register1("gender_id", { required: true })}
                                        >
                                            <option value={1}>{t("forms.woman")}</option>
                                            <option value={2}>{t("forms.man")}</option>
                                            <option value={3}>{t("forms.other")}</option>
                                        </select>


                                    </div>

                                    <div>
                                        <label htmlFor="phone">{t("forms.phone")}</label>
                                        <input
                                            type="text"
                                            name="phone"
                                            id="phone"
                                            defaultValue={userData.phone}
                                            {...register1("phone", { required: true })}
                                        />
                                        {errors1.phone && errors1.phone.type === "required" && (
                                            <span className="required">{t("forms.required")}</span>
                                        )}
                                    </div>
                                    <div>
                                        <label htmlFor="newsletters">{t("forms.newsletter")}</label>

                                        <div className="flex">
                                            <input
                                                type="checkbox"
                                                name="newsletters"
                                                id="newsletters"
                                                defaultChecked={userData.newsletters}
                                                {...register1("newsletters")}
                                            />
                                            <p>{t("forms.newsletter_confirmation")}</p>
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="birthday">{t("forms.birthday")}</label>
                                        <input
                                            type="date"
                                            name="birthday"
                                            id="birthday"
                                            defaultValue={userData.birthday ?? ""}
                                            max={new Date(Date.now() - 86400000).toISOString().split("T")[0]}
                                            {...register1("birthday")}
                                        />
                                    </div>

                                    <div className="total standalone-total">
                                        <button type="submit" className="blue-button" disabled={isSubmitting1}>
                                            {isSubmitting1 ? t("forms.sending_data") : t("forms.save_data")}
                                            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.50083 9H0.548828C1.05083 3.947 5.31383 0 10.4988 0C16.0218 0 20.4988 4.477 20.4988 10C20.4988 15.523 16.0218 20 10.4988 20C5.31383 20 1.05083 16.053 0.548828 11H8.49883V14L13.4988 10L8.49883 6V9H8.50083Z" fill="white" />
                                            </svg>
                                        </button>
                                    </div>
                                </form>
                            </div>

                            <div className="d-none profile-block left" style={{ order: 1 }}>
                                <div className="profile-icon">

                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 5 48 48">
                                        <path fill="white" d="M38.86 25.95c.08-.64.14-1.29.14-1.95s-.06-1.31-.14-1.95l4.23-3.31c.38-.3.49-.84.24-1.28l-4-6.93c-.25-.43-.77-.61-1.22-.43l-4.98 2.01c-1.03-.79-2.16-1.46-3.38-1.97l-.75-5.3c-.09-.47-.5-.84-1-.84h-8c-.5 0-.91.37-.99.84l-.75 5.3c-1.22.51-2.35 1.17-3.38 1.97l-4.98-2.01c-.45-.17-.97 0-1.22.43l-4 6.93c-.25.43-.14.97.24 1.28l4.22 3.31c-.08.64-.14 1.29-.14 1.95s.06 1.31.14 1.95l-4.22 3.31c-.38.3-.49.84-.24 1.28l4 6.93c.25.43.77.61 1.22.43l4.98-2.01c1.03.79 2.16 1.46 3.38 1.97l.75 5.3c.08.47.49.84.99.84h8c.5 0 .91-.37.99-.84l.75-5.3c1.22-.51 2.35-1.17 3.38-1.97l4.98 2.01c.45.17.97 0 1.22-.43l4-6.93c.25-.43.14-.97-.24-1.28l-4.22-3.31zm-14.86 5.05c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z" />
                                    </svg>

                                </div>

                                <form
                                    method="post"
                                    className="nice-form "
                                    onSubmit={handleSubmit2((data) => onSubmit2(data))}
                                >


                                    <div>
                                        <label htmlFor="cif">{t("forms.cif")}</label>
                                        <input
                                            type="text"
                                            name="cif"
                                            id="cif"
                                            defaultValue={userData.cif ?? ""}
                                            {...register2("cif")}
                                        />
                                    </div>

                                    <div>
                                        <label htmlFor="street">{t("forms.address")}</label>
                                        <input
                                            type="text"
                                            name="street"
                                            id="street"
                                            defaultValue={userData.street ?? ""}
                                            {...register2("street")}
                                        />
                                    </div>

                                    <div>
                                        <label htmlFor="postal_code">{t("forms.zip_code")}</label>
                                        <input
                                            type="text"
                                            name="postal_code"
                                            id="postal_code"
                                            defaultValue={userData.postal_code ?? ""}
                                            {...register2("postal_code")}
                                        />
                                    </div>

                                    <div>
                                        <label htmlFor="province">{t("forms.province")}</label>
                                        <input
                                            type="text"
                                            name="province"
                                            id="province"
                                            defaultValue={userData.province ?? ""}
                                            {...register2("province")}
                                        />
                                    </div>

                                    <div>
                                        <label htmlFor="country">{t("forms.country")}</label>
                                        <input
                                            type="text"
                                            name="country"
                                            id="country"
                                            defaultValue={userData.country ?? ""}
                                            {...register2("country")}
                                        />
                                    </div>

                                    <div className="total standalone-total">
                                        <button type="submit" className="blue-button" disabled={isSubmitting2}>
                                            {isSubmitting2 ? t("forms.sending_data") : t("forms.save_data")}
                                            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.50083 9H0.548828C1.05083 3.947 5.31383 0 10.4988 0C16.0218 0 20.4988 4.477 20.4988 10C20.4988 15.523 16.0218 20 10.4988 20C5.31383 20 1.05083 16.053 0.548828 11H8.49883V14L13.4988 10L8.49883 6V9H8.50083Z" fill="white" />
                                            </svg>
                                        </button>
                                    </div>
                                </form>
                            </div>

                            <div className="left both_ignore">

                                <div className="profile-block" style={{ order: 1 }}>
                                    <div className="profile-icon">
                                        <svg width="23" height="19" viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20.5 0.5H2.5C1.2625 0.5 0.26125 1.5125 0.26125 2.75L0.25 16.25C0.25 17.4875 1.2625 18.5 2.5 18.5H20.5C21.7375 18.5 22.75 17.4875 22.75 16.25V2.75C22.75 1.5125 21.7375 0.5 20.5 0.5ZM20.5 5L11.5 10.625L2.5 5V2.75L11.5 8.375L20.5 2.75V5Z" fill="white" />
                                        </svg>
                                    </div>

                                    <form
                                        method="post"
                                        className="nice-form"
                                        onSubmit={handleSubmit3((data) => onSubmit3(data))}
                                    >

                                        <div>
                                            <label htmlFor="email">{t("forms.email")}</label>
                                            <input
                                                type="email"
                                                name="email"
                                                id="email"
                                                defaultValue={userData.email}
                                                {...register3("email", { required: true })}
                                            />
                                            {errors3.email && errors3.email.type === "required" && (
                                                <span className="required">{t("forms.required")}</span>
                                            )}
                                            {bErrors3.email && (
                                                <span className="required">{bErrors3.email[0]}</span>
                                            )}
                                        </div>


                                        <div className="total standalone-total">
                                            <button type="submit" className="blue-button" disabled={isSubmitting3}>
                                                {isSubmitting3 ? t("forms.sending_data") : t("forms.save_data")}
                                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.50083 9H0.548828C1.05083 3.947 5.31383 0 10.4988 0C16.0218 0 20.4988 4.477 20.4988 10C20.4988 15.523 16.0218 20 10.4988 20C5.31383 20 1.05083 16.053 0.548828 11H8.49883V14L13.4988 10L8.49883 6V9H8.50083Z" fill="white" />
                                                </svg>
                                            </button>
                                        </div>
                                    </form>
                                </div>

                                <div className="profile-block">
                                    <div className="profile-icon">

                                        <svg width="26" height="8" viewBox="0 0 26 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13 1.33325V6.66659M10.3333 5.33325L15.6667 2.66659M10.3333 2.66659L15.6667 5.33325M3.66667 1.33325V6.66659M1 5.33325L6.33333 2.66659M1 2.66659L6.33333 5.33325M22.3333 1.33325V6.66659M19.6667 5.33325L25 2.66659M19.6667 2.66659L25 5.33325" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>

                                    </div>

                                    <form
                                        method="post"
                                        className="nice-form"
                                        onSubmit={handleSubmit4((data) => onSubmit4(data))}
                                    >

                                        <div>
                                            <label htmlFor="password">{t("forms.password")}</label>
                                            <input
                                                type="password"
                                                name="password"
                                                id="password"
                                                autoComplete="new-password"
                                                defaultValue={""}

                                                {...register4("password", { required: true })}
                                            />
                                            {bErrors4.password && (
                                                <span className="required">
                                                    {bErrors4.password[0]}
                                                </span>
                                            )}
                                        </div>
                                        <div>
                                            <label htmlFor="password_confirmation">
                                                Passw. confirm
                                            </label>
                                            <input
                                                type="password"
                                                name="password_confirmation"
                                                id="password_confirmation"
                                                autoComplete="new-password"
                                                defaultValue={""}
                                                {...register4("password_confirmation", {
                                                    validate: (val) => {
                                                        if (watch4("password") != val) {
                                                            return "Your passwords do no match";
                                                        }
                                                    },
                                                })}
                                            />
                                            {errors4.password_confirmation &&
                                                errors4.password_confirmation.type === "validate" && (
                                                    <span className="required">
                                                        {t("forms.pass_no_match")}
                                                    </span>
                                                )}
                                        </div>


                                        <div className="total standalone-total">
                                            <button type="submit" className="blue-button" disabled={isSubmitting4}>
                                                {isSubmitting4 ? t("forms.sending_data") : t("forms.save_data")}
                                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.50083 9H0.548828C1.05083 3.947 5.31383 0 10.4988 0C16.0218 0 20.4988 4.477 20.4988 10C20.4988 15.523 16.0218 20 10.4988 20C5.31383 20 1.05083 16.053 0.548828 11H8.49883V14L13.4988 10L8.49883 6V9H8.50083Z" fill="white" />
                                                </svg>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </UserHeader>
        </div>
    );
};

export default UserProfile;
