import EventProfile from "../components/EventProfile";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";

const IframeShop = () => {
  let { companySlug, eventSlug, promoter = null } = useParams();
  const [event, setEvent] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_DOMAIN +
          "/event/info/" +
          companySlug +
          "/" +
          eventSlug
      )
      .then((res) => {
        setEvent(res.data);
        setIsLoading(false);
      });
  }, [eventSlug, companySlug]);

  return (
    <>
      {!isLoading && (
        <EventProfile event={event} promoter={promoter} onlyTickets={true} />
      )}
    </>
  );
};

export default IframeShop;
