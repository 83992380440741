import LoginForm from "../components/LoginForm";
import RegisterForm from "../components/RegisterForm";
import { useNavigate } from "react-router-dom";

import { useAuthStore } from "../stores/AuthStore";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

// axios.defaults.xsrfCookieName = "XSRF-TOKEN";
// axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

const Login = () => {
    const { isLoggedIn } = useAuthStore();
    let navigate = useNavigate();

    const { t } = useTranslation();

    useEffect(() => {
        if (isLoggedIn) {
            navigate("/user/profile");
        }
    }, [isLoggedIn]);

    const handleLogin = (data) => {
        navigate("/user/profile");
    };

    const handleRegister = (data) => {
        navigate("/user/profile");
    };

    return (
        <div className="container">
            <div className="white-block">
                {/* <Link to="/">back</Link> */}

                {!isLoggedIn && (
                    <>
                        <div className="form-container">
                            <h1 className="login-title">{t("login")}</h1>
                            <LoginForm onSuccess={handleLogin} />
                        </div>
                        <div className="form-container">
                            <h1 className="login-title">{t("register")}</h1>
                            <RegisterForm onSubmit={handleRegister} />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Login;
