import "./styles/bootstrap.min.css";
import "./styles/app.scss";
import "./styles/home.scss";

// import footer header body
import Footer from "./layout/Footer";
import Header from "./layout/Header";
import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Company from "./containers/Company";
import Event from "./containers/Event";
import Home from "./containers/Home";
import Login from "./containers/Login";
import UserOrders from "./containers/UserOrders";
import UserProfile from "./containers/UserProfile";
import { useAuthStore } from "./stores/AuthStore";
import UserRefunds from "./containers/UserRefunds";
import Register from "./containers/Register";
import SetPassword from "./containers/SetPassword";

import NotFound from "./containers/NotFound";
import Contact from "./containers/Contact";
import Policies from "./containers/Policy";
import Terms from "./containers/Terms";
import Iframe from "./containers/Iframe";
import IframeShop from "./containers/IframeShop";
import { Template as HomeTemplate, HomeFooter } from "./containers/Home";


import "./i18n";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Template = ({ children }) => {

    return (
        <>
            {window.location.pathname.indexOf("iframe") === -1 && <Header />}
            <ToastContainer />

            <div className="main-block">{children}</div>
            {window.location.pathname.indexOf("iframe") === -1 && <HomeFooter />}
            
        </>
    );
};


const App = () => {
    const { init } = useAuthStore();

    useEffect(() => {
        init();
    }, [init]);

    return (
        <div className={`App ${window.location.pathname.indexOf("iframe") !== -1 ? 'iframe' : ''}`}>
            <Routes>
                <Route path="/:companySlug" element={<Template><Company /></Template>} />
                <Route path="/:companySlug/:eventSlug" element={<Template><Event /></Template>} />

                <Route path="/:companySlug/:eventSlug/iframe" element={<Iframe />} />
                <Route
                    path="/:companySlug/:eventSlug/iframe_shop"
                    element={<IframeShop />}
                />

                <Route path="/:companySlug/:eventSlug/:promoter" element={<Template><Event /></Template>} />

                <Route path="/user/profile" exact element={<Template><UserProfile /></Template>} />
                <Route path="/user/orders" exact element={<Template><UserOrders /></Template>} />
                <Route path="/user/refunds" exact element={<Template><UserRefunds /></Template>} />
                <Route path="/login" exact element={<HomeTemplate><Login /></HomeTemplate>} />
                <Route path="/register" exact element={<HomeTemplate><Register /></HomeTemplate>} />
                <Route path="/setPassword" exact element={<HomeTemplate><SetPassword /></HomeTemplate>} />
                <Route path="/logout" exact />
                <Route path="/" exact element={<Home />} />
                <Route path="/terminos" element={<HomeTemplate><Terms /></HomeTemplate>} />
                <Route path="/politicas" element={<HomeTemplate><Policies /></HomeTemplate>} />
                <Route path="/contacto" element={<HomeTemplate><Contact /></HomeTemplate>} />

                <Route path="/404" element={<NotFound />} />
            </Routes>

        </div>
    );
};

export default () => (
    <BrowserRouter>
        <App />
    </BrowserRouter>
);
