const Policies = () => {
  return (
    <div className="container">
      <div className="white-block">
        <div id="terminos">
          <h1>Política de privacidad</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae
            nisl euismod, ultricies nisl eu, aliquet nisl. Nulla facilisi. Nulla
            facilisi. Nulla facilisi. Nulla facilisi. Nulla facilisi. Nulla
            facilisi. Nulla facilisi. Nulla facilisi. Nulla facilisi. Nulla
            facilisi. Nulla facilisi. Nulla facilisi. Nulla facilisi. Nulla
            facilisi. Nulla facilisi. Nulla facilisi. Nulla
            <br />
            facilisi. Nulla facilisi. Nulla facilisi. Nulla facilisi. Nulla
            facilisi. Nulla facilisi. Nulla facilisi. Nulla facilisi. Nulla
          </p>
        </div>
      </div>
    </div>
  );
};

export default Policies;
