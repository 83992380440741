import { create } from "zustand";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';

axios.defaults.withCredentials = true;

const getUserData = (set) => {
    axios
        .get(process.env.REACT_APP_API_DOMAIN + "/auth/info")
        .then((response) => {
            set({ userData: response.data, isLoggedIn: true });
        })
        .catch((error) => {

            console.log(error);
        });
};



export const useAuthStore = create((set) => ({
    isLoggedIn: false,
    userData: {},
    init: () => {
        const sessionToken = localStorage.getItem("sessionToken");
        if (sessionToken !== null) {
            axios.defaults.headers.common["Authorization"] = `Bearer ${sessionToken}`;
            getUserData(set);
        }
    },

    getUserData: getUserData,
    loginRequest: (data, onLogin) => {
        return axios
            .post(process.env.REACT_APP_API_DOMAIN + "/auth/login", {
                email: data.email,
                password: data.password,
                remember: true,
            })
            .then((response) => {
                axios.defaults.headers.common[
                    "Authorization"
                ] = `Bearer ${response.data.token}`;
                localStorage.setItem("sessionToken", response.data.token);
                getUserData(set);
                if (onLogin) {
                    onLogin();
                }
            })
            .catch((error) => { 
                console.log(error);
                window.alert("Correo o contraseña incorrectos");
            });
    },
    rememberPasswordRequest: (data, onSuccess) => {
        return axios
            .post(process.env.REACT_APP_API_DOMAIN + "/auth/remember", data)
            .then((response) => {
                if (response.data.error) {
                    return toast.error(response.data.error);
                } else {
                    toast.success("Email enviado correctamente");
                }
                
                if (onSuccess) {
                    onSuccess(response);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    },
    setPasswordRequest: (data, onSuccess) => {
        return axios.post(process.env.REACT_APP_API_DOMAIN + "/auth/set-password", data)
            .then((response) => {
                if (response.data.error) {
                    return toast.error(response.data.error);
                } else {
                    toast.success("Contraseña cambiada correctamente");
                }
                // do login
                axios.defaults.headers.common[
                    "Authorization"
                ] = `Bearer ${response.data.token}`;
                localStorage.setItem("sessionToken", response.data.token);
                getUserData(set);

                if (onSuccess) {
                    onSuccess(response);
                }

            })
            .catch((error) => {
                console.log(error);
            });
    },
    contactRequest: (data, onSuccess) => {
        return axios.post(process.env.REACT_APP_API_DOMAIN + "/contact", data)
            .then((response) => {
                if (response.data.error) {
                    return toast.error(response.data.error);
                } else {
                    toast.success("Gracias por contactar con nosotros, te responderemos lo antes posible");
                }

            })
            .catch((error) => {
                console.log(error);
            });
    },
    

    logout: () => {
        axios.defaults.headers.common["Authorization"] = null;
        localStorage.removeItem("sessionToken");
        set({ userData: {}, isLoggedIn: false });
        window.location.href = "/";
    },
    registerRequest: (data, onErrors) => {
        return axios
            .post(process.env.REACT_APP_API_DOMAIN + "/auth/register", data)
            .then((response) => {
                axios.defaults.headers.common[
                    "Authorization"
                ] = `Bearer ${response.data.token}`;
                localStorage.setItem("sessionToken", response.data.token);

                getUserData(set);
               
            })
            .catch((err) => {
                onErrors(err.response.data.errors);
            });
    },
    updateUserRequest: (data, onErrors) => {
        return axios
            .post(process.env.REACT_APP_API_DOMAIN + "/auth/update", data)
            .then((response) => {
                toast.success("Guardado correctamente");
            })
            .catch((err) => {
                onErrors(err.response.data.errors);
            });
    },
}));

export default useAuthStore;
