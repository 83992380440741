import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t } = useTranslation();

  return (
      <div id="not-found">
          <h1>{t("page_not_found")}</h1>
          <img src="/svg/404.svg" alt="404" />
    </div>
  );
};

export default NotFound;
