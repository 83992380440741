import React from "react";

import "../styles/test.scss";

const EventsList = ({ company, place, events }) => {
    // events = [];
    const niceDate = (date) => {
        var date = new Date(date);
        var options = { year: "numeric", month: "long", day: "numeric" };
        return date.toLocaleDateString("es-ES", options);
    };
    const niceNum = (date) => {
        var date = new Date(date);
        var options = { day: "numeric" };
        return date.toLocaleDateString("es-ES", options);
    };
    const niceMonth = (date) => {
        var date = new Date(date);
        var options = { month: "short" };
        return date.toLocaleDateString("es-ES", options);
    };

    return (
        <div className="container center">
            <div id="events-list" className="white-block clearfix row">
                {events.map((a) => (
                    a.open_sessions.length > 0 && (
                            <div className="col-md-4 item" key={a.id}>
                                <a
                                    href={"/" + place.slug + "/" + a.slug}
                                    style={{ backgroundImage: `url(${a.image})` }}
                                >
                                    <div className="overlay">
                                        <div className="top">
                                            <div className="time">
                                                <div className="day">
                                                    {niceNum(a.open_sessions[0].start_day)}
                                                </div>
                                                <div className="month">
                                                    {niceMonth(a.open_sessions[0].start_day)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bottom">
                                            <div className="name ellipsis">{a.title}</div>
                                            <div className="date">
                                                <svg
                                                    width="18"
                                                    height="21"
                                                    viewBox="0 0 18 21"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M7.95 16.85L4.4 13.3L5.85 11.85L7.95 13.95L12.15 9.75L13.6 11.2L7.95 16.85ZM0 20.5V2.5H3V0.5H5V2.5H13V0.5H15V2.5H18V20.5H0ZM2 18.5H16V8.5H2V18.5Z"
                                                        fill="white"
                                                    />
                                                </svg>
                                                <span className="d">
                                            {niceDate(a.open_sessions[0].start_day)}
                                        </span>
                                                <svg
                                                    width="24"
                                                    height="25"
                                                    viewBox="0 0 24 25"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M15.5 12.5H12V7.5M3 12.5C3 13.6819 3.23279 14.8522 3.68508 15.9442C4.13738 17.0361 4.80031 18.0282 5.63604 18.864C6.47177 19.6997 7.46392 20.3626 8.55585 20.8149C9.64778 21.2672 10.8181 21.5 12 21.5C13.1819 21.5 14.3522 21.2672 15.4442 20.8149C16.5361 20.3626 17.5282 19.6997 18.364 18.864C19.1997 18.0282 19.8626 17.0361 20.3149 15.9442C20.7672 14.8522 21 13.6819 21 12.5C21 11.3181 20.7672 10.1478 20.3149 9.05585C19.8626 7.96392 19.1997 6.97177 18.364 6.13604C17.5282 5.30031 16.5361 4.63738 15.4442 4.18508C14.3522 3.73279 13.1819 3.5 12 3.5C10.8181 3.5 9.64778 3.73279 8.55585 4.18508C7.46392 4.63738 6.47177 5.30031 5.63604 6.13604C4.80031 6.97177 4.13738 7.96392 3.68508 9.05585C3.23279 10.1478 3 11.3181 3 12.5Z"
                                                        stroke="white"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                                <span className="d">
                                            {a.open_sessions[0].start_hour.slice(0, 5)} -&nbsp;
                                                    {a.open_sessions[0].end_hour.slice(0, 5)}
                                        </span>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                    )
                ))}

                {events.length === 0 && (
                    <div className="col-md-12">
                        <div className="empty">
                            <div className="icon">
                            </div>
                            <div className="text">
                                No hay eventos disponibles
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default EventsList;
