import { create } from "zustand";
import axios from "axios";

export const useOrdersStore = create((set) => ({
    orders: [],
    refunds: [],


    getOrders: () => {
        axios
            .get(process.env.REACT_APP_API_DOMAIN + "/profile/orders")
            .then((response) => {
                set({ orders: response.data.orders });
            })
            .catch((error) => {
                console.log(error);
                window.alert("Correo o contraseña incorrectos");
            });
    },

    getRefunds: () => {
        axios
            .get(process.env.REACT_APP_API_DOMAIN + "/profile/refunds")
            .then((response) => {
                set({ refunds: response.data.refunds });
            })
            .catch((error) => {
                console.log(error);
                window.alert("Correo o contraseña incorrectos");
            });
    },
    newOrder: (data, onSuccess) => {
        axios
            .post(process.env.REACT_APP_API_DOMAIN + "/newOrder", data)
            .then((response) => {
                if (onSuccess) {
                    onSuccess();
                }
            })
            .catch((error) => { });
    },
    newRefund: (data, onSuccess) => {
        axios
            .post(process.env.REACT_APP_API_DOMAIN + "/newRefund", data)
            .then((response) => {
                if (onSuccess) {
                    onSuccess();
                }
            })
            .catch((error) => { });
    },
}));

export default useOrdersStore;
