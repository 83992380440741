import RegisterForm from "../components/RegisterForm";
import { redirect } from "react-router-dom";

import { useAuthStore } from "../stores/AuthStore";
import { useNavigate } from "react-router-dom";

// axios.defaults.xsrfCookieName = "XSRF-TOKEN";
// axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

const Register = () => {
    const { isLoggedIn } = useAuthStore();
    let navigate = useNavigate();

  const handleRegister = (data) => {
    redirect("/");
  };
    
  if (isLoggedIn) {
    navigate("/user/profile");
  }

  return (
    <div className="container">
      <div className="white-block">
        {/* <Link to="/">back</Link> */}

        {!isLoggedIn && (
          <>
            <div>
              <h1>Register</h1>
              <RegisterForm onSubmit={handleRegister} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Register;
