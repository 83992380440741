import CompanyHeader from "../components/CompanyHeader";
import EventProfile from "../components/EventProfile";
import axios from "axios";
import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {Helmet} from "react-helmet";

const Event = () => {
  let { companySlug, eventSlug, promoter = null } = useParams();
  const [event, setEvent] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const pageTitle = event.title + ' - goCrush.club'

  let navigate = useNavigate();

  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_DOMAIN +
          "/event/info/" +
          companySlug +
          "/" +
          eventSlug
      )
      .then((res) => {
        setEvent(res.data);
        setIsLoading(false);
      }).catch((err) => {
        // redirect to /404
        navigate("/404");
      });
  }, [eventSlug, companySlug]);

  return (
    <>
      {!isLoading && (
        <CompanyHeader
          background={event.company.background_image}
          id="event-header"
        >
          <Helmet>
            <title>{pageTitle}</title>
          </Helmet>
          <EventProfile event={event} promoter={promoter} />
        </CompanyHeader>
      )}
    </>
  );
};

export default Event;
