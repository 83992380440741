import { useForm } from "react-hook-form";
import { useAuthStore } from "../stores/AuthStore";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const RegisterForm = ({ onSuccess }) => {
    const { registerRequest } = useAuthStore();
    const { t } = useTranslation();

    const [bErrors, setBErrors] = useState([]);

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors, isSubmitting },
    } = useForm();

    const onSubmit = (data) => {
        return registerRequest(data, (err) => {
            setBErrors(err);
        });
    };

    return (
        <div>
            <form
                className="nice-form"
                onSubmit={handleSubmit((data) => onSubmit(data))}
            >
                <div>
                    <label htmlFor="email">{t("forms.email")}</label>
                    <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder={t("forms.write_email")}

                        {...register("email", { required: true })}
                    />
                    {errors.email && (
                        <span className="required">{t("forms.required")}</span>
                    )}
                    {bErrors.email && (
                        <span className="required">{bErrors.email[0]}</span>
                    )}
                </div>

                <div>
                    <label htmlFor="email_confirmation">
                        {t("forms.email_confirmation")}
                    </label>
                    <input
                        type="text"
                        name="email_confirmation"
                        id="email_confirmation"
                        placeholder={t("forms.write_email_confirmation")}

                        {...register("email_confirmation", {
                            required: true,
                            validate: (value) => value === watch("email"),
                        })}
                    />
                    {errors.email_confirmation &&
                        errors.email_confirmation.type === "validate" && (
                            <span className="required">{t("forms.email_no_match")}</span>
                        )}
                </div>

                <div>
                    <label htmlFor="name">{t("forms.name")}</label>
                    <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder={t("forms.write_name")}

                        {...register("name", { required: true })}
                    />

                    {errors.name && (
                        <span className="required">{t("forms.required")}</span>
                    )}
                </div>
                <div>
                    <label htmlFor="surname">{t("forms.surname")}</label>
                    <input
                        type="text"
                        name="surname"
                        id="surname"
                        placeholder={t("forms.write_surname")}
                        {...register("surname", { required: true })}
                    />
                    {errors.surname && (
                        <span className="required">{t("forms.required")}</span>
                    )}
                </div>

                <div>
                    <label htmlFor="gender_id">{t("forms.gender")}</label>
                    <select
                        name="gender_id"
                        id="gender_id"
                        // defaultValue={userData.gender_id}
                        {...register("gender_id", { required: true })}
                    >
                        <option value={1}>{t("forms.woman")}</option>
                        <option value={2}>{t("forms.man")}</option>
                        <option value={3}>{t("forms.other")}</option>
                    </select>
                </div>
                <div>
                    <label htmlFor="birthday">{t("forms.birthday")}</label>
                    <input
                        type="date"
                        name="birthday"
                        id="birthday"
                        // defaultValue={userData.birthday ?? ""}
                        max={new Date(Date.now() - 86400000).toISOString().split("T")[0]}
                        {...register("birthday", { required: true })}
                    />
                       {errors.birthday && (
                        <span className="required">{t("forms.required")}</span>
                    )}
                </div>
                <div>
                    <label htmlFor="password">{t("forms.password")}</label>
                    <input
                        type="password"
                        name="password"
                        id="password"
                        placeholder={t("forms.write_password")}

                        {...register("password", { required: true })}
                    />
                    {errors.password && (
                        <span className="required">{t("forms.required")}</span>
                    )}
                    {bErrors.password && (
                        <span className="required">{bErrors.password[0]}</span>
                    )}
                </div>
                <div>
                    <label htmlFor="password_confirmation">
                        {t("forms.pass_confirmation")}
                    </label>
                    <input
                        type="password"
                        name="password_confirmation"
                        id="password_confirmation"
                        placeholder={t("forms.write_password_confirmation")}
                        {...register("password_confirmation", {
                            required: true,
                            validate: (value) => value === watch("password"),
                        })}
                    />
                    {errors.password_confirmation &&
                        errors.password_confirmation.type === "validate" && (
                            <span className="required">{t("forms.pass_no_match")}</span>
                        )}
                </div>
                <div>
                    <label htmlFor="phone">{t("forms.phone")}</label>
                    <input
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder={t("forms.write_phone")}
                        {...register("phone", { required: true })}
                    />
                    {errors.phone && (
                        <span className="required">{t("forms.required")}</span>
                    )}
                </div>

                <div className="blue-button-container login-button-container">
                    <button type="submit" className="simple-button login-button" disabled={isSubmitting}>
                        {t("register")}

                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.5 16L16.5 12L12.5 8L11.1 9.4L12.7 11H8.5V13H12.7L11.1 14.6L12.5 16ZM12.5 22C11.1167 22 9.81667 21.7373 8.6 21.212C7.38333 20.6867 6.325 19.9743 5.425 19.075C4.525 18.175 3.81267 17.1167 3.288 15.9C2.76333 14.6833 2.50067 13.3833 2.5 12C2.5 10.6167 2.76267 9.31667 3.288 8.1C3.81333 6.88333 4.52567 5.825 5.425 4.925C6.325 4.025 7.38333 3.31267 8.6 2.788C9.81667 2.26333 11.1167 2.00067 12.5 2C13.8833 2 15.1833 2.26267 16.4 2.788C17.6167 3.31333 18.675 4.02567 19.575 4.925C20.475 5.825 21.1877 6.88333 21.713 8.1C22.2383 9.31667 22.5007 10.6167 22.5 12C22.5 13.3833 22.2373 14.6833 21.712 15.9C21.1867 17.1167 20.4743 18.175 19.575 19.075C18.675 19.975 17.6167 20.6877 16.4 21.213C15.1833 21.7383 13.8833 22.0007 12.5 22Z" fill="white" />
                        </svg>
                    </button>
                </div>
            </form>
        </div>
    );
};

export default RegisterForm;
