import FormField from "./FormField";
import { useAuthStore } from "../../../stores/AuthStore";
import { useTranslation } from "react-i18next";

const BillingDetailsFields = () => {
  const { isLoggedIn, userData } = useAuthStore();
  const { t } = useTranslation();
  return (
    <>
      {isLoggedIn && (
        <>
          {/*          <FormField
            name="name"
            label={t("forms.name")}
            type="text"
            placeholder={t("forms.name")}
            defaultValue={userData.name + " " + userData.surname}
            required
          />
          <FormField
            name="email"
            label={t("forms.email")}
            type="hidden"
            placeholder={t("forms.example_email")}
            defaultValue={userData.email}
            required
          /> */}
          {/*  <FormField
            name="address"
            label={t("forms.address")}
            type="text"
            placeholder={t("forms.example_address")}
            defaultValue={userData.street}
            required
          />
          <FormField
            name="city"
            label={t("forms.city")}
            type="text"
            placeholder={t("forms.city")}
            defaultValue={userData.street}
            required
          />
          <FormField
            name="state"
            label={t("forms.province")}
            type="text"
            placeholder={t("forms.province")}
            defaultValue={userData.province}
            required
          />
          <FormField
            name="zip"
            label={t("forms.zip_code")}
            type="text"
            placeholder={t("forms.zip_code")}
            defaultValue={userData.postalCode}
            required
          /> */}
        </>
      )}
    </>
  );
};

export default BillingDetailsFields;
