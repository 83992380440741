import axios from "axios";
import React from "react";
import Widget from "./ticket-shop/Widget";
import Calendar from "./ticket-shop/Calendar";

import dateFormat from "dateformat";

import Select from "react-select";
import UserInfos from "./ticket-shop/UserInfos";
import { useState, useEffect } from "react";
import { useAuthStore } from "../stores/AuthStore";
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";
import CheckoutForm from "./ticket-shop/CheckoutForm";
import { Tooltip } from "react-tooltip";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import "../styles/test.scss";
import Form from "./ticket-shop/Form";

import { useTranslation } from "react-i18next";
import Timer from "./ticket-shop/Timer";
import { DownloadSampleCsvFile } from "./download-pdf/link.tsx";
import { NavLink } from "react-router-dom";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC);
const TicketShop = ({
    event,
    sessions,
    currentSessionId,
    setCurrentSessionId,
    currentStep,
    setCurrentStep,
    promoter,
    reservedOrderId,
    setReservedOrderId,
    selectedTickets,
    setSelectedTickets,
    discount,
    setDiscount,

    getTotal,
    errorQuantityDiscount,
    getSelectedTicketsCount,
}) => {
    const { isLoggedIn, userData } = useAuthStore();

    const [order, setOrder] = useState(null);
    const [isBeingReserved, setIsBeingReserved] = useState(false);

    const [usersInfos, setUsersInfos] = useState({});

    const { t } = useTranslation();

    const [startDate, setStartDate] = useState(0);
    const [seconds, setSeconds] = useState(10 * 60);
    const [intervalId, setIntervalId] = useState(null);

    const [isDiscountVisible, setIsDiscountVisible] = useState(false);

    const toogleDiscountVisible = function () {
        setIsDiscountVisible(!isDiscountVisible);
    };

    const pluck = (key) => (array) =>
        Array.from(new Set(array.map((obj) => obj[key])));
    const getGroupLabels = pluck("group_label");

    if (promoter) {
        const now = new Date()
        const item = {
            promoter: promoter,
            expiry: now.getTime() + 24 * 60 * 60 * 1000,
        }
        localStorage.setItem("promoter", JSON.stringify(item));
    }

    useEffect(() => {
        if (startDate > 0) {
            if (intervalId != null) {
                clearInterval(intervalId);
            }
            setIntervalId(
                setInterval(() => {
                    let diff = Math.floor(startDate + 10 * 60 - Date.now() / 1000);
                    setSeconds(diff);
                }, 100)
            );
        }
    }, [startDate]);

    const onHeader = function (step) {
        if (currentStep > step) {
            setCurrentStep(step);
        }
    };

    /* useEffect(() => {
        if (currentSessionId == null && sessions.length > 0) {
          setCurrentSessionId(sessions[0].id);
        }
      }, [sessions, currentSessionId, setCurrentSessionId]); */

    useEffect(() => {
        setSelectedTickets({});
    }, [currentSessionId]);

    var self = this;

    //   let ticketsCount = getSelectedTicketsCount(selectedTickets);

    const onSuccesPayment = function (paymentResult) {
        setCurrentStep((currentStep) => currentStep + 1);
        setReservedOrderId(null);
    };

    function checkTicketsSelected() {
        if (getSelectedTicketsCount(selectedTickets) > 0) {
            reserveTickets();
        }
    }

    function getDiscountFromCode(event) {
        event.preventDefault();
        let code = document.getElementById("discount-code")
            ? document.getElementById("discount-code").value
            : "";
        if (code == "") {
            code = "none";
        }
        axios
            .get(
                process.env.REACT_APP_API_DOMAIN +
                "/discounts/" +
                currentSessionId +
                "/" +
                code
            )
            .then((response) => {
                setDiscount(response.data.discount);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    function areFormsReady(usersInfosArray) {
        let empty = false;
        let emptyids = [];

        for (let i = 0; i < getSelectedTicketsCount(selectedTickets); i++) {
            if (typeof usersInfosArray[i] === "undefined") {
                usersInfosArray[i] = {};
            }

            ["name", "surname", "email"].forEach((field) => {
                if (
                    typeof usersInfosArray[i][field] === "undefined" ||
                    usersInfosArray[i][field] == ""
                ) {
                    if (event.named_tickets == 1) {
                        emptyids.push(field + "-" + i);
                        empty = true;
                    } else {
                        usersInfosArray[i][field] = "";
                    }
                }
            });

            // check forms with oneby_id = 2 (by ticket)
            event.forms
                .filter((form) => form.oneby_id == 2 /* && form.is_mandatory == 1 */)
                .forEach((form) => {
                    if (
                        typeof usersInfosArray[i]["form_" + form.id] === "undefined" ||
                        usersInfosArray[i]["form_" + form.id] == ""
                    ) {
                        if (document.querySelector("#form-" + form.id + "-" + i)) {
                            if (event.named_tickets == 1) {
                                emptyids.push("form-" + form.id + "-" + i);
                                empty = true;
                            } else {
                                usersInfosArray[i]["form_" + form.id] = "";
                            }
                        }
                    }
                });
        }

        // check forms with oneby_id = 1 (by order)
        event.forms
            .filter((form) => form.oneby_id == 1 /* && form.is_mandatory == 1 */)
            .forEach(function (form) {
                if (
                    typeof usersInfosArray["form_" + form.id] === "undefined" ||
                    usersInfosArray["form_" + form.id] == ""
                ) {
                    // check if exists
                    if (document.querySelector("#form-" + form.id + "-global")) {
                        emptyids.push("form-" + form.id + "-global");
                        empty = true;
                    }
                }
            });

        // remove all error classes
        document.querySelectorAll(".error").forEach((el) => {
            el.classList.remove("error");
        });

        // add error classes
        emptyids.forEach((id) => {
            document.getElementById(id).classList.add("error");
        });

        return !empty;
    }

    const reserveTickets = function () {
        let promoter = null;
        const promoterItem = localStorage.getItem("promoter")
        if (promoterItem) {
            const item = JSON.parse(promoterItem)
            const now = new Date()
            // compare the expiry time of the item with the current time
            if (now.getTime() > item.expiry) {
                localStorage.removeItem("promoter")
            } else {
                promoter = item.promoter;
            }
        }

        let data = {
            selectedTickets: selectedTickets,
            sessionId: currentSessionId,
            code: document.getElementById("discount-code")
                ? document.getElementById("discount-code").value
                : "",
            promoter: promoter,
        };

        axios
            .post(process.env.REACT_APP_API_DOMAIN + "/orders/newReserve", data)
            .then((response) => {
                if (response.data.error) {
                    alert(response.data.error);
                    // refresh page
                    window.location.reload();
                } else {
                    setReservedOrderId(response.data.order.code);
                    setStartDate(Date.now() / 1000);
                    setCurrentStep((currentStep) => currentStep + 1);

                    setOrder(response.data.order);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const dataReady = function (extra = 0) {
        if (!areFormsReady(usersInfos)) {
            return false;
        }
        if (isBeingReserved) {
            return false;
        }
        setIsBeingReserved(true);
        let data = {
            selectedTickets: selectedTickets,
            usersInfos: usersInfos,
            sessionId: currentSessionId,
            code: document.getElementById("discount-code")
                ? document.getElementById("discount-code").value
                : "",
            promoter: promoter,
            reservedOrderId: reservedOrderId,
        };

        // save order
        axios
            .post(
                process.env.REACT_APP_API_DOMAIN +
                "/orders/newOrder" +
                "?token=" +
                localStorage.getItem("sessionToken"),
                data
            )
            .then((response) => {
                if (response.data.error) {
                    alert(response.data.error);
                    // refresh page
                    window.location.reload();
                } else {
                    if (response.data.order.quantity == 0) {
                        setCurrentStep((currentStep) => currentStep + 2 + extra);
                    } else {
                        setCurrentStep((currentStep) => currentStep + 1 + extra);
                    }
                    setOrder(response.data.order);
                }
                setIsBeingReserved(false);
            })
            .catch((error) => {
                console.log(error);
                setIsBeingReserved(false);
            });

        return false;
    };

    function handleLogin() { }

    function handleRegister() { }

    function toggleTicketsDownload() {
        document.getElementById("tickets-download").classList.toggle("open");
    }

    const mergeTickettypes = (ev, sessionId) => {
        if (!sessionId) return [];

        let session = sessions.filter((session) => {
            return session.id == sessionId;
        })[0];

        // sort by order
        session.tickettypes.sort((a, b) => {
            if (a.order == null) {
                a.order = 100000;
            }
            if (b.order == null) {
                b.order = 100000;
            }
            return a.order - b.order;
        });

        return session.tickettypes;
    };

    if (sessions.length == 0) {
        return (
            <div className="white-block empty-events">
                No hay sesiones disponibles actualmente
            </div>
        );
    }

    function toggleForms() {
        document.getElementById("login-f").classList.toggle("d-none");
        document.getElementById("register-f").classList.toggle("d-none");
    }

    function ticketNumUp(tickettypeId) {
        var selectedTicketsCopy = { ...selectedTickets };
        if (typeof selectedTicketsCopy[tickettypeId] === "undefined") {
            selectedTicketsCopy[tickettypeId] = 0;
        }

        // check available tickets
        let currentTT = sessions
            .filter((session) => {
                return session.id == currentSessionId;
            })[0]
        ["tickettypes"].filter((tt) => {
            return tt.id == tickettypeId;
        })[0];

        if (currentTT.available > selectedTicketsCopy[tickettypeId]) {
            selectedTicketsCopy[tickettypeId] = Math.min(
                parseInt(selectedTicketsCopy[tickettypeId]) + 1,
                10
            );
            setSelectedTickets(selectedTicketsCopy);
        }
    }
    function onCancel() {
        // setCurrentStep((currentStep) => currentStep - 1)
        if (window.confirm(t("event.cancel_order_confirmation"))) {
            // refresh page
            window.location.reload();
        }
    }
    function ticketNumDown(tickettypeId) {
        var selectedTicketsCopy = { ...selectedTickets };
        if (typeof selectedTicketsCopy[tickettypeId] === "undefined") {
            selectedTicketsCopy[tickettypeId] = 0;
        }
        selectedTicketsCopy[tickettypeId] = Math.max(
            0,
            parseInt(selectedTicketsCopy[tickettypeId]) - 1
        );
        setSelectedTickets(selectedTicketsCopy);
    }

    function getGlobalForms() {
        let forms = [];
        let formIds = [];
        let id;
        for(id in selectedTickets) {
            let tickettype = sessions
            .filter((session) => {
                return session.id == currentSessionId;
            })[0]["tickettypes"].filter((tt) => {
                return tt.id == id;
            })[0];
            if (tickettype.forms) {
                tickettype.forms.forEach((form) => {
                    if (form.oneby_id == 1) {
                        // check if form is already in array
                        if (formIds.indexOf(form.id) == -1) {
                            formIds.push(form.id);
                            forms.push(form);
                        }
                    }
                })
            }
        }
        return forms;
    }

    function logginFormOk() {
        if (event.named_tickets == 1) {
            // need to fill
            setCurrentStep((currentStep) => currentStep + 1);
        } else {
            // check if all forms are filled, named tickets will avoid throwing an error for name, surname and email
            if (areFormsReady(usersInfos)) {
                dataReady(1);
            } else {
                setCurrentStep((currentStep) => currentStep + 1);

                // remove error on init
                document.querySelectorAll(".error").forEach((el) => {
                    el.classList.remove("error");
                });
            }
        }
    }

    return (
        <Elements stripe={stripePromise}>
            <>
                {reservedOrderId && <Timer seconds={seconds} />}

                <div>
                    <Widget
                        order="0"
                        title={t("event.choose_session")}
                        open={currentStep == 0}
                        onSuccess={() => setCurrentStep((currentStep) => currentStep + 1)}
                        submitEnabled={currentSessionId != null}
                        submitText={
                            t("event.confirm_date_and_time") +
                            `<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.1 14.6L16.15 7.55L14.75 6.15L9.1 11.8L6.25 8.95L4.85 10.35L9.1 14.6ZM10.5 20C9.11667 20 7.81667 19.7373 6.6 19.212C5.38333 18.6867 4.325 17.9743 3.425 17.075C2.525 16.175 1.81267 15.1167 1.288 13.9C0.763333 12.6833 0.500667 11.3833 0.5 10C0.5 8.61667 0.762667 7.31667 1.288 6.1C1.81333 4.88333 2.52567 3.825 3.425 2.925C4.325 2.025 5.38333 1.31267 6.6 0.788C7.81667 0.263333 9.11667 0.000666667 10.5 0C11.8833 0 13.1833 0.262667 14.4 0.788C15.6167 1.31333 16.675 2.02567 17.575 2.925C18.475 3.825 19.1877 4.88333 19.713 6.1C20.2383 7.31667 20.5007 8.61667 20.5 10C20.5 11.3833 20.2373 12.6833 19.712 13.9C19.1867 15.1167 18.4743 16.175 17.575 17.075C16.675 17.975 15.6167 18.6877 14.4 19.213C13.1833 19.7383 11.8833 20.0007 10.5 20Z" fill="white"/>
            </svg>`
                        }
                        onHeader={() => onHeader(0)}
                    >
                        {event && (
                            <Calendar
                                setCurrentSessionId={setCurrentSessionId}
                                sessions={sessions}
                                currentSessionId={currentSessionId}
                                currentStep={currentStep}
                            ></Calendar>
                        )}
                    </Widget>
                    <Widget
                        order="1"
                        title={""}
                        open={currentStep == 1}
                        // onSuccess={reserveTickets}
                        submitEnabled={getSelectedTicketsCount(selectedTickets) > 0}
                        onHeader={() => onHeader(1)}
                        onCancel={onCancel}
                        key={currentSessionId}
                        defaultClass="widget tickets"
                    >
                        <>
                            {getGroupLabels(mergeTickettypes(event, currentSessionId)).map(
                                (groupLabel, index) => {
                                    return (
                                        <div key={index} className="group">
                                            <div className="group-label widget-header">
                                                {groupLabel != "enums.tickettypegroup.ticket"
                                                    ? t(groupLabel)
                                                    : t("event.choose_tickets")}
                                            </div>

                                            {mergeTickettypes(event, currentSessionId).map(
                                                (tickettype, index) => {
                                                    if (tickettype.group_label == groupLabel) {
                                                        return (
                                                            <div
                                                                key={tickettype.id}
                                                                className="tickettype flex"
                                                                data-order={tickettype.order}
                                                            >
                                                                <div className="name-description">
                                                                    <div className="name">
                                                                        {tickettype.name}
                                                                        {tickettype.legal_text && (
                                                                            <div
                                                                                className="legal-text"
                                                                                style={{ display: "inline-block" }}
                                                                            >
                                                                                <small
                                                                                    className="small-i"
                                                                                    data-tooltip-id={
                                                                                        `tooltip-` + tickettype.id
                                                                                    }
                                                                                    data-tooltip-html={
                                                                                        tickettype.legal_text.split('\n').map((item, key) => {
                                                                                            return item + "<br />"
                                                                                        })
                                                                                    }
                                                                                >
                                                                                    i
                                                                                </small>
                                                                                <Tooltip
                                                                                    id={`tooltip-` + tickettype.id}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <div className="description">
                                                                        {tickettype.description}
                                                                    </div>

                                                                    <div className="tags">
                                                                        {tickettype.available <= 0 ? (
                                                                            <>
                                                                                <div className="tag price-soldout">
                                                                                    {tickettype.price}€
                                                                                </div>
                                                                                <div className="tag soldout">
                                                                                    {t("event.sold_out")} 💔
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <div className="tag price">
                                                                                {tickettype.price}€
                                                                            </div>
                                                                        )}

                                                                        {tickettype.is_oferta == 1 && (
                                                                            <>
                                                                                <div className="tag offer">
                                                                                    {t("event.is_offer")} ⭐
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>

                                                                    {tickettype.ignore_discounts == 1 && (
                                                                        <div className="sub-title">
                                                                            {t("event.no_discount")}
                                                                        </div>
                                                                    )}
                                                                    {tickettype.show_capacity == 1 &&
                                                                        tickettype.quantity && (
                                                                            <div className="sub-title">
                                                                                {t("event.available_tickets")}:{" "}
                                                                                {tickettype.available}
                                                                            </div>
                                                                        )}
                                                                </div>
                                                                <div className="price-quantity">
                                                                    <div className="quantity">
                                                                        {tickettype.available > 0 ? (
                                                                            <div>
                                                                                <button
                                                                                    className="mini-btn"
                                                                                    onClick={() =>
                                                                                        ticketNumDown(tickettype.id)
                                                                                    }
                                                                                >
                                                                                    <div className="text">-</div>
                                                                                </button>

                                                                                <span className="number">
                                                                                    {selectedTickets[tickettype.id] ?? 0}
                                                                                </span>

                                                                                <button
                                                                                    className="mini-btn"
                                                                                    onClick={() =>
                                                                                        ticketNumUp(tickettype.id)
                                                                                    }
                                                                                >
                                                                                    <div className="text">+</div>
                                                                                </button>
                                                                            </div>
                                                                        ) : (
                                                                            <div className="soldout">
                                                                                <button className="mini-btn">
                                                                                    <div className="text">-</div>
                                                                                </button>

                                                                                <span className="number">
                                                                                    {selectedTickets[tickettype.id] ?? 0}
                                                                                </span>

                                                                                <button className="mini-btn">
                                                                                    <div className="text">+</div>
                                                                                </button>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                }
                                            )}
                                        </div>
                                    );
                                }
                            )}

                            {event &&
                                currentSessionId &&
                                mergeTickettypes(event, currentSessionId).length == 0 ? (
                                <div className="no-tickets">
                                    {t("event.no_tickets_available")}
                                </div>
                            ) : null}
                        </>

                        {mergeTickettypes(event, currentSessionId).length > 0 ? (
                            <>
                                <div className="discounts nice-form">
                                    <div className="mini-title" onClick={toogleDiscountVisible}>
                                        {t("event.apply_your_discount_code")}

                                        <div className="toggle">
                                            <svg
                                                width="16"
                                                height="9"
                                                viewBox="0 0 16 9"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M1 1L8 8L15 1"
                                                    stroke="#F2F2F2"
                                                    fill="black"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    {isDiscountVisible && (
                                        <form
                                            className="discount-form"
                                            onSubmit={getDiscountFromCode}
                                        >
                                            <input
                                                id="discount-code"
                                                type="text"
                                                placeholder={t("event.discount_code")}
                                            />
                                            <button className="" type="submit">
                                                {t("event.apply")}
                                            </button>
                                        </form>
                                    )}

                                    <div className="discount-error">{discount.error}</div>
                                    <div className="discount-message">{discount.message}</div>
                                </div>
                                <div className="total">
                                    <div className="total-text">
                                        <span className="float-left">
                                            {" "}
                                            {t("event.tickets_price")}
                                        </span>
                                        {discount.message &&
                                            errorQuantityDiscount() &&
                                            getSelectedTicketsCount(selectedTickets) > 0 ? (
                                            <div className="error-q-discount">
                                                {errorQuantityDiscount()}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        {discount.message &&
                                            !errorQuantityDiscount() &&
                                            getSelectedTicketsCount(selectedTickets) > 0 ? (
                                            <div className="old-price">
                                                {getTotal(
                                                    mergeTickettypes(event, currentSessionId),
                                                    selectedTickets,
                                                    false
                                                )}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        {getTotal(
                                            mergeTickettypes(event, currentSessionId),
                                            selectedTickets,
                                            discount
                                        )}
                                        €
                                    </div>
                                    <div className="total-text user-fee">
                                        <span className="float-left">
                                            {" "}
                                            {t("event.admin_fee")}
                                            <small
                                                className="small-i"
                                                data-tooltip-id="my-tooltip"
                                                data-tooltip-content="Gracias a esta aportación, podemos ofrecerte los mejores y más seguros servicios de tickets."
                                            >
                                                i
                                            </small>
                                        </span>
                                        <Tooltip id="my-tooltip" />
                                        {event.user_fee &&
                                            getTotal(
                                                mergeTickettypes(event, currentSessionId),
                                                selectedTickets,
                                                discount,
                                                event.user_fee
                                            )}
                                        €
                                    </div>
                                    <div className="total-text">
                                        <span className="float-left">
                                            {" "}
                                            {t("event.total_to_pay")}
                                        </span>
                                        {getTotal(
                                            mergeTickettypes(event, currentSessionId),
                                            selectedTickets,
                                            discount,
                                            1 + parseFloat(event.user_fee)
                                        )}
                                        €
                                    </div>
                                    <div className="total-submit">
                                        {
                                            <a
                                                href="#"
                                                onClick={onCancel}
                                                className="button-link-cancel"
                                                style={{ float: "left", fontSize: "14px" }}
                                            >
                                                {t("event.cancel")}
                                            </a>
                                        }

                                        <button
                                            onClick={checkTicketsSelected}
                                            disabled={!getSelectedTicketsCount(selectedTickets) > 0}
                                            className="simple-button"
                                        >
                                            {t("event.buy_tickets")}{" "}
                                            <svg
                                                width="24"
                                                height="16"
                                                viewBox="0 0 24 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M0 2.75C0 2.15326 0.237053 1.58097 0.65901 1.15901C1.08097 0.737053 1.65326 0.5 2.25 0.5H21.75C22.3467 0.5 22.919 0.737053 23.341 1.15901C23.7629 1.58097 24 2.15326 24 2.75V5C24 5.19891 23.921 5.38968 23.7803 5.53033C23.6397 5.67098 23.4489 5.75 23.25 5.75C22.6533 5.75 22.081 5.98705 21.659 6.40901C21.2371 6.83097 21 7.40326 21 8C21 8.59674 21.2371 9.16903 21.659 9.59099C22.081 10.0129 22.6533 10.25 23.25 10.25C23.4489 10.25 23.6397 10.329 23.7803 10.4697C23.921 10.6103 24 10.8011 24 11V13.25C24 13.8467 23.7629 14.419 23.341 14.841C22.919 15.2629 22.3467 15.5 21.75 15.5H2.25C1.65326 15.5 1.08097 15.2629 0.65901 14.841C0.237053 14.419 0 13.8467 0 13.25L0 11C0 10.8011 0.0790176 10.6103 0.21967 10.4697C0.360322 10.329 0.551088 10.25 0.75 10.25C1.34674 10.25 1.91903 10.0129 2.34099 9.59099C2.76295 9.16903 3 8.59674 3 8C3 7.40326 2.76295 6.83097 2.34099 6.40901C1.91903 5.98705 1.34674 5.75 0.75 5.75C0.551088 5.75 0.360322 5.67098 0.21967 5.53033C0.0790176 5.38968 0 5.19891 0 5V2.75ZM6 1.25V2.75H7.5V1.25H6ZM7.5 5.75V4.25H6V5.75H7.5ZM18 5.75V4.25H16.5V5.75H18ZM16.5 2.75H18V1.25H16.5V2.75ZM7.5 7.25H6V8.75H7.5V7.25ZM18 8.75V7.25H16.5V8.75H18ZM7.5 10.25H6V11.75H7.5V10.25ZM18 11.75V10.25H16.5V11.75H18ZM6 13.25V14.75H7.5V13.25H6ZM16.5 14.75H18V13.25H16.5V14.75Z"
                                                    fill="white"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </>
                        ) : (
                            ""
                        )}
                    </Widget>
                </div>
                <div>
                    <Widget
                        order="2"
                        title={t("event.log_in_your_account")}
                        open={currentStep == 2}
                        onCancel={onCancel}
                        onSuccess={logginFormOk}
                        onHeader={() => onHeader(2)}
                        submitEnabled={isLoggedIn}
                    >
                        <div className="center">
                            <img src="/svg/login.svg" alt="login" />
                        </div>
                        {isLoggedIn ? (
                            <div className="logged-in">
                                <div className="title">{t("event.buy_as")}:</div>
                                <div className="logged-in-name">
                                    {userData.name} {userData.surname}
                                </div>
                                <div className="logged-in-email">{userData.email}</div>
                            </div>
                        ) : (
                            <>
                                {/* <div>{t("login_or_register")}</div> */}
                                <div id="login-f">
                                    <h3>
                                        {t("login")}
                                        <a href="#" onClick={toggleForms}>
                                            {t("create_new_account")}
                                        </a>
                                    </h3>
                                    <LoginForm onSuccess={handleLogin} />
                                </div>
                                <div id="register-f" className="d-none">
                                    <h3>
                                        {t("register")}
                                        <a href="#" onClick={toggleForms}>
                                            {t("i_have_an_account")}
                                        </a>
                                    </h3>
                                    <RegisterForm onSubmit={handleRegister} />
                                </div>
                            </>
                        )}
                    </Widget>
                </div>
                <div>
                    <Widget
                        order="3"
                        title={t("event.tickets_data")}
                        open={currentStep == 3}
                        // onChange={areFormsReady}
                        onCancel={onCancel}
                        onSuccess={() => dataReady()}
                        onHeader={() => onHeader(3)}
                    >
                        <div className="center">
                            <img src="/svg/datos.svg" alt="login" />
                        </div>
                        <UserInfos
                            is_named={event.named_tickets}
                            count={getSelectedTicketsCount(selectedTickets)}
                            setUsersInfos={(data) => setUsersInfos(data)}
                            usersInfos={usersInfos}
                            selectedTickets={selectedTickets}
                            tickettypes={mergeTickettypes(event, currentSessionId)}
                            forms={event.forms.filter((form) => form.oneby_id == 2)}
                        />
                        {getGlobalForms().map((form, index) => {
                                return (
                                    <div key={index} className="nice-form user-info">
                                        <Form
                                            key={index}
                                            remoteid="global"
                                            form={form}
                                            onChangeEvent={(e) => {
                                                let _usersInfo = { ...usersInfos };
                                                _usersInfo[e.target.name] = e.target.value;
                                                setUsersInfos(_usersInfo);
                                            }}
                                        />
                                    </div>
                                );
                            })}
                    </Widget>
                </div>
                <div>
                    <Widget
                        order="4"
                        title={t("event.payment_data")}
                        open={currentStep == 4}
                        onHeader={() => onHeader(4)}
                    >
                        <div className="center">
                            <img src="/svg/credit.svg" alt="login" />
                        </div>
                        {order ? (
                            <CheckoutForm
                                price={getTotal(
                                    mergeTickettypes(event, currentSessionId),
                                    selectedTickets,
                                    discount,
                                    1 + parseFloat(event.user_fee)
                                )}
                                onCancel={onCancel}
                                order={order}
                                onSuccessfulCheckout={onSuccesPayment}
                            />
                        ) : (
                            <div className="loadingSvg">
                                <svg
                                    width="50px"
                                    height="50px"
                                    viewBox="0 0 100 100"
                                    preserveAspectRatio="xMidYMid"
                                    className="lds-rolling"
                                >
                                    <circle
                                        cx="50"
                                        cy="50"
                                        fill="none"
                                        stroke="#fff"
                                        strokeWidth="10"
                                        r="35"
                                        strokeDasharray="164.93361431346415 56.97787143782138"
                                        transform="rotate(0 50 50)"
                                    >
                                        <animateTransform
                                            attributeName="transform"
                                            type="rotate"
                                            repeatCount="indefinite"
                                            dur="1s"
                                            values="0 50 50;360 50 50"
                                            keyTimes="0;1"
                                        ></animateTransform>
                                    </circle>
                                </svg>
                            </div>
                        )}
                    </Widget>
                </div>
                <div>
                    <Widget
                        order="5"
                        title={t("event.download_your_tickets")}
                        open={currentStep == 5}
                        // onChange={areFormsReady}
                        onHeader={() => onHeader(5)}
                    >
                        <div className="center">
                            <img src="/svg/downloads.svg" alt="login" />
                        </div>

                        <div id="all-tickets-download" className="tickets-download">
                            {order && (
                                <div className="ticket">
                                    <div className="svg">
                                        <img src="/svg/ticket.svg" alt="ticket" />
                                    </div>
                                    <div className="ticket-title">
                                        <div className="event-name">{event.title}</div>
                                        <div className="event-date">
                                            <svg
                                                width="13"
                                                height="15"
                                                viewBox="0 0 13 15"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M5.74167 12.2625L3.17778 9.6L4.225 8.5125L5.74167 10.0875L8.775 6.9375L9.82222 8.025L5.74167 12.2625ZM0 15V1.5H2.16667V0H3.61111V1.5H9.38889V0H10.8333V1.5H13V15H0ZM1.44444 13.5H11.5556V6H1.44444V13.5Z"
                                                    fill="black"
                                                />
                                            </svg>

                                            {dateFormat(
                                                sessions /* .filter((session) => {
                          return session.id == currentSessionId;
                        }) */[0].start_day,
                                                "dd-mm-yyyy"
                                            )}
                                        </div>

                                        <DownloadSampleCsvFile
                                            url={
                                                process.env.REACT_APP_API_DOMAIN +
                                                `/ticket/order/` +
                                                order.code
                                            }
                                            name={"gocrush_" + event.slug}
                                        >
                                            {t("event.download_tickets")}

                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                style={{ verticalAlign: "middle", marginLeft: "15px" }}
                                            >
                                                <path
                                                    d="M10 0C11.3132 0 12.6136 0.258658 13.8268 0.761205C15.0401 1.26375 16.1425 2.00035 17.0711 2.92893C17.9997 3.85752 18.7362 4.95991 19.2388 6.17317C19.7413 7.38642 20 8.68678 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C8.68678 20 7.38642 19.7413 6.17317 19.2388C4.95991 18.7362 3.85752 17.9997 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0ZM10 15L15 10H12V6H8V10H5L10 15Z"
                                                    fill="black"
                                                />
                                            </svg>
                                        </DownloadSampleCsvFile>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div id="tickets-download" className="tickets-download">
                            <p
                                onClick={toggleTicketsDownload}
                                style={{ verticalAlign: "middle", fontWeight: "bold" }}
                            >
                                {t("event.download_by_individual_ticket")}
                                <svg
                                    className="arrow"
                                    width="21"
                                    height="10"
                                    viewBox="0 0 21 10"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ verticalAlign: "middle", marginLeft: "15px" }}
                                >
                                    <path
                                        d="M1.18575 1.62332C1.04767 1.51102 0.978634 1.37063 0.978634 1.21621C0.978634 1.06178 1.04767 0.921396 1.18575 0.809088C1.46191 0.584471 1.91066 0.584471 2.18682 0.809088L10.9893 7.96875L19.7918 0.809088C20.068 0.584471 20.5167 0.584471 20.7929 0.809088C21.069 1.0337 21.069 1.39871 20.7929 1.62332L11.4899 9.19011C11.2137 9.41472 10.7649 9.41472 10.4888 9.19011L1.18575 1.62332Z"
                                        fill="black"
                                    />
                                </svg>
                            </p>
                            <div className="content">
                                {/* {JSON.stringify(order)} */}
                                {order && order.tickets
                                    ? order.tickets.map((ticket, index) => {
                                        return (
                                            <div key={index} className="ticket">
                                                <div className="svg">
                                                    <img src="/svg/ticket.svg" alt="ticket" />
                                                </div>
                                                <div className="ticket-title">
                                                    <div className="event-name">{event.title}</div>
                                                    <div className="event-date">
                                                        <svg
                                                            width="13"
                                                            height="15"
                                                            viewBox="0 0 13 15"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M5.74167 12.2625L3.17778 9.6L4.225 8.5125L5.74167 10.0875L8.775 6.9375L9.82222 8.025L5.74167 12.2625ZM0 15V1.5H2.16667V0H3.61111V1.5H9.38889V0H10.8333V1.5H13V15H0ZM1.44444 13.5H11.5556V6H1.44444V13.5Z"
                                                                fill="black"
                                                            />
                                                        </svg>

                                                        {dateFormat(
                                                            sessions.filter((session) => {
                                                                return session.id == currentSessionId;
                                                            })[0].start_day,
                                                            "dd-mm-yyyy"
                                                        )}
                                                    </div>
                                                    <div className="event-place">
                                                        <svg
                                                            width="14"
                                                            height="14"
                                                            viewBox="0 0 14 14"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M6.99967 0.333008C7.88373 0.333008 8.73158 0.684197 9.3567 1.30932C9.98182 1.93444 10.333 2.78229 10.333 3.66634C10.333 4.5504 9.98182 5.39824 9.3567 6.02336C8.73158 6.64849 7.88373 6.99967 6.99967 6.99967C6.11562 6.99967 5.26777 6.64849 4.64265 6.02336C4.01753 5.39824 3.66634 4.5504 3.66634 3.66634C3.66634 2.78229 4.01753 1.93444 4.64265 1.30932C5.26777 0.684197 6.11562 0.333008 6.99967 0.333008ZM6.99967 8.66634C10.683 8.66634 13.6663 10.158 13.6663 11.9997V13.6663H0.333008V11.9997C0.333008 10.158 3.31634 8.66634 6.99967 8.66634Z"
                                                                fill="black"
                                                            />
                                                        </svg>{" "}
                                                        {usersInfos[index] ? usersInfos[index].name : ""}
                                                    </div>

                                                    <DownloadSampleCsvFile
                                                        url={
                                                            process.env.REACT_APP_API_DOMAIN +
                                                            `/ticket/` +
                                                            ticket.code
                                                        }
                                                        name={"gocrush_" + event.slug}
                                                    >
                                                        {t("event.download_ticket")}

                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 20 20"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            style={{
                                                                verticalAlign: "middle",
                                                                marginLeft: "5px",
                                                            }}
                                                        >
                                                            <path
                                                                d="M10 0C11.3132 0 12.6136 0.258658 13.8268 0.761205C15.0401 1.26375 16.1425 2.00035 17.0711 2.92893C17.9997 3.85752 18.7362 4.95991 19.2388 6.17317C19.7413 7.38642 20 8.68678 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C8.68678 20 7.38642 19.7413 6.17317 19.2388C4.95991 18.7362 3.85752 17.9997 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0ZM10 15L15 10H12V6H8V10H5L10 15Z"
                                                                fill="black"
                                                            />
                                                        </svg>
                                                    </DownloadSampleCsvFile>
                                                </div>
                                            </div>
                                        );
                                    })
                                    : ""}
                            </div>
                            <div className="links">
                                <NavLink
                                    //   activeClassName="is-active"
                                    to="/user/orders"
                                >
                                    <svg
                                        width="21"
                                        height="21"
                                        viewBox="0 0 21 21"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M10.5007 0.833252C11.7825 0.833252 13.0119 1.34248 13.9183 2.2489C14.8248 3.15533 15.334 4.38471 15.334 5.66659C15.334 6.94847 14.8248 8.17784 13.9183 9.08427C13.0119 9.99069 11.7825 10.4999 10.5007 10.4999C9.21877 10.4999 7.98939 9.99069 7.08297 9.08427C6.17654 8.17784 5.66732 6.94847 5.66732 5.66659C5.66732 4.38471 6.17654 3.15533 7.08297 2.2489C7.98939 1.34248 9.21877 0.833252 10.5007 0.833252ZM10.5007 12.9166C15.8415 12.9166 20.1673 15.0795 20.1673 17.7499V20.1666H0.833984V17.7499C0.833984 15.0795 5.15982 12.9166 10.5007 12.9166Z"
                                            fill="white"
                                        />
                                    </svg>

                                    {t("my_tickets")}
                                </NavLink>

                                <NavLink
                                    //   activeClassName="is-active"
                                    // onClick={() => { window.location.reload() }}
                                    to="/user/profile"
                                >
                                    <svg
                                        className="svg-icon"
                                        viewBox="0 0 1024 1024"
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill="white"
                                            d="M887.7 180.9H641.1c4.8-13.9 7.4-28.9 7.4-44.4C648.5 61.1 587.4 0 512 0S375.5 61.1 375.5 136.5c0 15.5 2.6 30.5 7.4 44.4H136.3c-50.2 0-90.8 40.7-90.8 90.8v661.4c0 50.2 40.7 90.8 90.8 90.8h751.4c50.2 0 90.8-40.7 90.8-90.8V271.8c0-50.2-40.6-90.9-90.8-90.9zM512 70c36.7 0 66.5 29.8 66.5 66.5 0 17.1-6.5 32.6-17 44.4h-98.9c-10.7-11.8-17.1-27.3-17.1-44.4 0-36.7 29.8-66.5 66.5-66.5z m396.5 863.2c0 11.5-9.4 20.8-20.8 20.8H136.3c-11.5 0-20.8-9.4-20.8-20.8V271.8c0-11.5 9.4-20.8 20.8-20.8h751.4c11.5 0 20.8 9.4 20.8 20.8v661.4z"
                                        />
                                        <path
                                            fill="white"
                                            d="M747.3 569.5L477.8 839c-10.5 10.5-27.6 10.5-38.1 0l-87.1-87.1c-13.7-13.7-35.8-13.7-49.5 0s-13.7 35.8 0 49.5l87.1 87.1c18.9 18.9 43.7 28.3 68.5 28.3s49.6-9.4 68.5-28.3L796.8 619c13.7-13.7 13.7-35.8 0-49.5s-35.8-13.7-49.5 0zM307.3 434.9h409.4c19.3 0 35-15.7 35-35s-15.7-35-35-35H307.3c-19.3 0-35 15.7-35 35s15.7 35 35 35zM580.8 624.8c19.3 0 35-15.7 35-35s-15.7-35-35-35H313.3c-19.3 0-35 15.7-35 35s15.7 35 35 35h267.5z"
                                        />
                                    </svg>
                                    {t("my_profile")}
                                </NavLink>
                            </div>
                        </div>
                    </Widget>
                </div>
            </>
        </Elements>
    );
};

export default TicketShop;
