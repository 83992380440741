import axios from "axios";
import React, { useState } from "react";
import { useDownloadFile } from "../../customHooks/useDownloadFile.ts";
import { Alert } from "react-bootstrap";
import { useAuthStore } from "../../stores/AuthStore";

export const DownloadSampleCsvFile: React.FC = (props) => {
    const [buttonState, setButtonState] = useState<string>(
        "normal"
    );
    const preDownloading = () => setButtonState("loading");
    const postDownloading = () => setButtonState("normal");
    const sessionToken = localStorage.getItem("sessionToken");

    const { isLoggedIn, userData, logout } = useAuthStore();

    const downloadSampleCsvFile = function (url: string) {

        if (buttonState === "loading") {
            return;
        }

        // throw new Error("uncomment this line to mock failure of API");
        return axios.get(
            url,
            {
                responseType: "blob",
                /* 
                headers: {
                  Authorization: "Bearer <token>", // add authentication information as required by the backend APIs.
                },
                 */
            }
        );
    };


    const atts = {
        apiDefinition: downloadSampleCsvFile,
        href: props.url + '.pdf',
        preDownloading,
        postDownloading,
    };


    // const { ref, url, download, name } = useDownloadFile(atts);
    return (
        /* <>
                <a href={url}  download={props.name + '.pdf'} className="d-none" ref={ref} />
                <div className={`a ${buttonState}`} onClick={download}>
                    {props.children}
                </div>
            </> */
        <>
            <a href={props.url + '.pdf?token=' + sessionToken } className={`a ${buttonState}`}>
                {props.children}
            </a>
            {/* <div className={`a ${buttonState}`} onClick={download}> */}
            
            {/* </div> */}
        </>
    );
};