// This component is used to get name, username and email from a list of users
import React from "react";
import { useAuthStore } from "../../stores/AuthStore";
import Form from "./Form";

import { useTranslation } from "react-i18next";

const UserInfo = ({ is_named, onChange, defaultUser, tickettypeName, forms, id }) => {
  const userData = useAuthStore((state) => state.userData);

  const onChangeEvent = (e) => {
    onChange(e.target.name, e.target.value);
  };

  const { t } = useTranslation();

  return (
    <>
          <div className={`user-info nice-form ${is_named ? '' : 'd-none'}`}>
              <div className="title">Entrada {id + 1} - <i className="text-muted">{tickettypeName}</i></div>
        <div className="user-info__name">
          <label htmlFor="name">
            {t("forms.name")} <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            name="name"
            id={"name-" + id}
            onChange={onChangeEvent}
            required
            defaultValue={defaultUser ? userData.name : ""}
          />
        </div>
        <div className="user-info__username">
          <label htmlFor="surname">
            {t("forms.surname")} <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            name="surname"
            id={"surname-" + id}
            onChange={onChangeEvent}
            required
            defaultValue={defaultUser ? userData.surname : ""}
          />
        </div>
        <div className="user-info__email">
          <label htmlFor="email">
            {t("forms.email")} <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            name="email"
            id={"email-" + id}
            onChange={onChangeEvent}
            required
            defaultValue={defaultUser ? userData.email : ""}
          />
        </div>

        {forms.map((form, idx) => {
          return (
            <Form
              key={idx}
              form={form}
              remoteid={id}
              onChangeEvent={onChangeEvent}
            />
          );
        })}
      </div>
    </>
  );
};

export default UserInfo;
