// This component is used to get name, username and email from a list of users
import React, { useEffect } from "react";
import UserInfo from "./UserInfo";
import { useAuthStore } from "../../stores/AuthStore";

const UserInfos = ({
    is_named,
  usersInfos,
  setUsersInfos,
  count,
  selectedTickets,
  tickettypes,
  forms,
}) => {
  const { isLoggedIn, userData } = useAuthStore();

  useEffect(() => {
    if (isLoggedIn) {
      let _usersInfos = { ...usersInfos };
      _usersInfos[0] = {
        name: userData.name,
        surname: userData.surname,
        email: userData.email,
      };
      setUsersInfos(_usersInfos);
    }
  }, [isLoggedIn]);

  let self = this;
  function updatedIdxField(idx, name, value) {
    let _usersInfos = { ...usersInfos };
    if (typeof _usersInfos[idx] === "undefined") {
      _usersInfos[idx] = {};
    }
    _usersInfos[idx][name] = value;
    setUsersInfos(_usersInfos);
  }

  // get array of tickettypes from selectedTickets
  let tickettypesArray = [];
  Object.keys(selectedTickets).forEach((key) => {
    for (let i = 0; i < parseInt(selectedTickets[key]); i++) {
      tickettypesArray.push(
        tickettypes.find((tickettype) => tickettype.id == key)
      );
    }
  });

  let userForms = [];
    for (let i = 0; i < count; i++) {
    if (i == 0) {
      userForms.push(
        <UserInfo
          key={i}
          id={i}
          onChange={(name, value) => updatedIdxField(i, name, value)}
          defaultUser={true}
          tickettypeName={tickettypesArray[i].name}
              forms={tickettypesArray[i].forms.filter((form) => form.tickettype_id == tickettypesArray[i].id)}
              is_named={is_named}
          //   tickettypeId={tickettypesArray[i].id}
        />
      );
    } else {
      userForms.push(
        <UserInfo
          key={i}
          id={i}
          onChange={(name, value) => updatedIdxField(i, name, value)}
          tickettypeName={tickettypesArray[i].name}
          forms={tickettypesArray[i].forms.filter((form) => form.tickettype_id == tickettypesArray[i].id)}
          is_named={is_named}
          //   tickettypeId={tickettypesArray[i].id}
        />
      );
    }
  }

  //   userData = userData.slice(0, count);

  return (
    <div className="user-infos">
      {/* <div className="test">{count}</div> */}
      {isLoggedIn && userForms}
    </div>
  );
};

export default UserInfos;
