const Terms = () => {
  return (
    <div className="container">
      <div className="white-block">
        <div id="terminos">
          <h1>Términos y condiciones</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae
            nisl euismod, ultricies nisl eu, aliquet nisl. Nulla facilisi.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Terms;
