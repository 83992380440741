import UserHeader from "../components/UserHeader";
import { useAuthStore } from "../stores/AuthStore";
import { useOrdersStore } from "../stores/OrdersStore";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const UserRefunds = () => {
  const { isLoggedIn } = useAuthStore();

  const { refunds, getRefunds } = useOrdersStore();

  const { t } = useTranslation();

  useEffect(() => {
    if (isLoggedIn) {
      getRefunds();
    }
  }, [isLoggedIn, getRefunds]);

  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = d.getMonth() + 1;
    const day = d.getDate();
    // const hours = d.getHours();
    // const minutes = d.getMinutes();
    // const seconds = d.getSeconds();
    return `${day}/${month}/${year}`;
  };

  const toggleHiddenTickets = (e) => {
    e.preventDefault();
    const tickets =
      e.target.parentElement.parentElement.querySelector(".tickets");
    tickets.classList.toggle("hidden");
  };

  return (
    <div className="container">
      <UserHeader />

      <div className="">
        {refunds.map((refund) => (
          <div key={refund.id} className="refund white-block">
            <p className="name">{refund.email}</p>
            <p className="date">{formatDate(refund.created_at)}</p>
            <p className="price">{refund.quantity}</p>
            <p className="tickets-btn">
              <a className="" onClick={toggleHiddenTickets}>
                {t("refunds.number_returned")}: {refund.tickets.length}
              </a>
            </p>
            <div className="tickets hidden">
              {refund.tickets.map((item) => {
                return (
                  <div key={item.id} className="ticket">
                    <p className="name">
                      {item.tickettype.name} # {item.code}
                    </p>
                    <p className="description">{item.tickettype.description}</p>
                    <p className="data">
                      {item.name} {item.surname} {item.email}
                    </p>

                    <p className="price">{item.tickettype.price}eur</p>
                  </div>
                );
              })}
            </div>
          </div>
        ))}

        {refunds.length == 0 && (
          <div className="white-block">
            <h1 className="name">{t("refunds.no_refunds")}</h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserRefunds;
