import CompanyHeader from "../components/CompanyHeader";
import EventsList from "../components/EventsList";
import React, { useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet';


const Company = () => {
    let { companySlug } = useParams();

    const { t } = useTranslation();

    const [company, setCompany] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    let navigate = useNavigate();
    const pageTitle = company.name + ' - goCrush.club'


    useEffect(() => {
        axios
            .get(process.env.REACT_APP_API_DOMAIN + "/place/info/" + companySlug)
            .then((res) => {
                setCompany(res.data);
                setIsLoading(false);
            })
            .catch((err) => {
                // redirect to /404
                navigate("/404");
            });
    }, [companySlug, navigate]);

    return (
        <div>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <CompanyHeader
                id="company-header"
                background={company.background_image}
            // url={"/" + company.slug}
            >
                {!isLoading && (
                    <>
                        <div id="company-contact" className="container">
                            <div className="logo-name">
                                <div className="company-logo">
                                    <div
                                        style={{ backgroundImage: `url(${company.logo_image})` }}
                                    ></div>
                                </div>
                                <div className="company-name">
                                    <span className="name">{company.name}</span>
                                    <br />
                                    {/* <span dangerouslySetInnerHTML={{ __html: company.description }}></span> */}
                                </div>
                            </div>
                        </div>
                        <EventsList company={company} place={company.place} events={company.get_open_events} />
                    </>
                )}
            </CompanyHeader>
        </div>
    );
};

export default Company;
