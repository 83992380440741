import React from "react";
import { useTranslation } from "react-i18next";
import Progress from "./Progress";

const Widget = ({
    onHeader,
    order,
    title,
    onSuccess,
    onCancel,
    open,
    submitEnabled = true,
    submitText = "",
    defaultClass = "widget",
    children,
}) => {
    const { t } = useTranslation();

    return (
        <div id={`widget-order-${order}`}>
            {open && (
                <div
                    className={`widget-header` + (submitEnabled ? " enabled" : "")}
                    // style={{ backgroundColor: "var(--main_color)" }}
                    onClick={onHeader}
                >
                    {/* <span className="widget-order">{order}</span> */}
                    <span className="widget-title">{title}</span>

                    <Progress step={order} />
                </div>
            )}

            <div className={`${open ? "open white-block " + defaultClass : defaultClass}`}>
                {/* <div
          className={`widget-header` + (submitEnabled ? " enabled" : "")}
          style={{ backgroundColor: "var(--main_color)" }}
          onClick={onHeader}
        >
          <span className="widget-order">{order}</span>
          <span className="widget-title">{title}</span>
        </div> */}
                <div className={`widget-body`}>
                    {children}

                    {onSuccess && (
                        <div className="widget-footer">

                            {onCancel && (
                                <a href="#" onClick={onCancel} className="button-link-cancel">
                                    {t("event.cancel")}
                                </a>)}
                            <button
                                onClick={onSuccess}
                                disabled={!submitEnabled}
                                className="simple-button"
                                dangerouslySetInnerHTML={{
                                    __html: submitText || t("event.continue"),
                                }}
                            ></button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Widget;
