import "../styles/test.scss";
import { Link } from "react-router-dom";
import { useAuthStore } from "../stores/AuthStore";
import { useTranslation } from "react-i18next";

const Header = () => {
    const { isLoggedIn, userData, logout } = useAuthStore();

    const { i18n, t } = useTranslation();

    const onChangeLang = (e) => {
        const lang_code = e.target.value;
        i18n.changeLanguage(lang_code);
        localStorage.setItem("lang", lang_code);
    };

    return (
        <>
            <nav className="user-login">

                <div className="logo">
                    {/* <a href="/"> */}
                        <img src="/logo.svg" />
                    {/* </a> */}
                </div>

                <div id="center">
                    <div id="select-container" className="">
                        <div
                            className="img"
                            style={{ "backgroundImage": `url("/flags/${i18n.language}.svg")` }}
                            alt="es"
                        />
                        <select
                            defaultValue={i18n.language}
                            onChange={onChangeLang}
                            className="fake-link d-none d-md-inline-block"
                        >
                            {[
                                { label: "Spanish", code: "es" },
                                { label: "English", code: "en" },
                            ].map(({ code, label }) => (
                                <option key={code} value={code}>
                                    {label}
                                </option>
                            ))}
                        </select>
                        <select
                            defaultValue={i18n.language}
                            onChange={onChangeLang}
                            className="fake-link d-inline-block d-md-none"
                        >
                            {[
                                { label: "ES", code: "es" },
                                { label: "EN", code: "en" },
                            ].map(({ code, label }) => (
                                <option key={code} value={code}>
                                    {label}
                                </option>
                            ))}
                        </select>
                    </div>

                </div>

                {!isLoggedIn && (
                    <div>

                        <a href="/login" className="button-style">
                            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.5 19.78C8.625 19.78 6.0835 18.308 4.6 16.1C4.6345 13.8 9.2 12.535 11.5 12.535C13.8 12.535 18.3655 13.8 18.4 16.1C17.6397 17.2321 16.6127 18.1598 15.4095 18.8016C14.2063 19.4433 12.8637 19.7793 11.5 19.78ZM11.5 3.45C12.415 3.45 13.2925 3.81348 13.9395 4.46048C14.5865 5.10748 14.95 5.985 14.95 6.9C14.95 7.815 14.5865 8.69252 13.9395 9.33952C13.2925 9.98652 12.415 10.35 11.5 10.35C10.585 10.35 9.70748 9.98652 9.06048 9.33952C8.41348 8.69252 8.05 7.815 8.05 6.9C8.05 5.985 8.41348 5.10748 9.06048 4.46048C9.70748 3.81348 10.585 3.45 11.5 3.45ZM11.5 0C9.9898 0 8.49438 0.297456 7.09914 0.875385C5.7039 1.45331 4.43615 2.3004 3.36827 3.36827C1.2116 5.52494 0 8.45001 0 11.5C0 14.55 1.2116 17.4751 3.36827 19.6317C4.43615 20.6996 5.7039 21.5467 7.09914 22.1246C8.49438 22.7025 9.9898 23 11.5 23C14.55 23 17.4751 21.7884 19.6317 19.6317C21.7884 17.4751 23 14.55 23 11.5C23 5.1405 17.825 0 11.5 0Z" fill="white" />
                            </svg>
                            {t("login")}
                        </a>
                    </div>
                )}

                {isLoggedIn && (
                    <>
                        <Link
                            to="/user/profile"
                            className=""
                            style={{ marginRight: "20px" }}
                        >
                            {userData.name}
                        </Link>
                        <div href="/logout" className=" fake-link logout" onClick={logout}>
                            Logout
                        </div>
                    </>
                )}
            </nav>
        </>
    );
};

export default Header;
