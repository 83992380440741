import { useForm } from "react-hook-form";
import { useAuthStore } from "../stores/AuthStore";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

const PasswordForm = ({ onSuccess }) => {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const { setPasswordRequest, rememberPasswordRequest } = useAuthStore();

    const onSubmit = (data) => {
        // get params from url
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get("token");
        data.token = token;
        setPasswordRequest(data, onSuccess);
    };

    const { t } = useTranslation();
    
    return (
        <div>
            <form
                className="nice-form"
                onSubmit={handleSubmit((data) => onSubmit(data))}
            >
                <div>
                    <label htmlFor="password">{t("forms.new_password")}</label>
                    <input
                        type="password"
                        name="password"
                        id="password"
                        {...register("password", { required: true })}
                    />
                    {errors.password && (
                        <span className="required">{t("forms.required")}</span>
                    )}
                </div>
                <div>
                    <label htmlFor="password_confirmation">
                        {t("forms.pass_confirmation")}
                    </label>
                    <input
                        type="password"
                        name="password_confirmation"
                        id="password_confirmation"
                        {...register("password_confirmation", {
                            required: true,
                            validate: (value) => value === watch("password"),
                        })}
                    />
                    {errors.password_confirmation &&
                        errors.password_confirmation.type === "validate" && (
                            <span className="required">{t("forms.pass_no_match")}</span>
                        )}
                </div>
                <div className="blue-button-container">
                
                    <button type="submit" className="blue-button">
                        {t("reset_password")}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default PasswordForm;
